export default theme => ({
  searchHeader: {
    backgroundColor: theme.palette.primary.main,
    fontFamily: 'CormorantGaramond-Regular',
    padding: '1rem',
    color: '#FFF',
    fontSize: '1.5rem',
  },
  filtersWrapper: {
    position: 'relative',
    display: 'flex',
    borderBottom: `1px solid ${theme.palette.others.mediumGrey}`,
  },
  flex1: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    padding: '0.5rem',
  },
  flex2: {
    display: 'flex',
    alignItems: 'center',
    flex: 2,
    padding: '0.5rem',
  },
  label: {
    paddingRight: '1rem',
  },
  secondLabel: {
    padding: '0 1rem',
  },
  filterButton: {
    position: 'absolute',
    right: 0,
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    height: '1.75rem',
    width: '12rem',
    borderRadius: '1rem',
    bottom: '-0.9rem',
    fontSize: '0.9rem',
  },
  filterButtonIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#000',
    borderRadius: '50%',
    marginLeft: '0.5rem',
    marginRight: '-0.75rem',
  },
});
