import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

import categories from '../categories';
import history from '../../../../../../history';

const Categories = ({ classes }) => {
  const handleLogout = async () => {
    history.push('/logout');
  };

  return (
    <Fragment>
      {categories.map(category => (
        <Grid item xs={12} key={`category-${category.name}`}>
          <NavLink
            to={category.link}
            className={classes.navLink}
            activeClassName={classes.selected}
          >
            {category.name}
          </NavLink>
        </Grid>
      ))}
      <Grid item xs={12}>
        <button type="button" onClick={handleLogout} className={classes.logout}>
          Déconnexion
        </button>
      </Grid>
    </Fragment>
  );
};

Categories.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default Categories;
