import React from 'react';
import './popup.css';
import PropTypes from 'prop-types';

/**
 * popup for validate a choice
 * @param {func} onValidate when validate button is clicked
 * @param {func} onCancel when refuse button is clicked
 */

const Popup = ({ display, content, onValidate, onCancel }) =>
  display && (
    <div className="popup-validation">
      <div className="popup-inner-validation">
        {content}
        <button type="button" onClick={onValidate}>
          Valider
        </button>
        <button type="button" onClick={onCancel}>
          Refuser
        </button>
      </div>
    </div>
  );

Popup.propTypes = {
  display: PropTypes.bool.isRequired,
  content: PropTypes.string.isRequired,
  onValidate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default Popup;
