import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import './card.css';
import { wineTypes } from '../../../../wines/config';
import { StoreContext } from '../../../../../stores';
import { toDate } from '../../../../admin/config';

const Card = ({ currentOffer }) => {
  /* eslint-disable */
  const [state, dispatch] = useContext(StoreContext);
  const color = wineTypes[currentOffer.vintage.wine.color];
  /* eslint-enable */

  // click to delete an offer, display the popup for validation
  const handleClick = () => {
    dispatch({
      type: 'setState',
      payload: {
        key: 'currentSelectedOffer',
        value: currentOffer,
      },
    });

    dispatch({
      type: 'setState',
      payload: {
        key: 'displayDeleteOffer',
        value: true,
      },
    });
  };

  return (
    <div className="div-row">
      <div className="lign-border" />
      <div className="div-card">
        <div className="fill-grey">
          {toDate(currentOffer.date)}{' '}
          <div className="float-right">
            <span className="span-round">...</span>
            <button className="color-red" onClick={handleClick} type="button">
              X
            </button>{' '}
          </div>
        </div>
        <div className="margin-top">
          <span className={`icon-${color}`} />
          <span className="font">{currentOffer.vintage.wine.name}</span> <br />
          <div className="div-flex-row">
            {currentOffer.vintage.year} <div className="div-heigth" />
            {currentOffer.format},{currentOffer.packaging}
          </div>
        </div>
        <div className="div-flex-row">
          <div className="div-grey-price">
            Prix <br /> {currentOffer.price} €
          </div>
          <div className="div-cv-qte">CV</div>
          <div className="div-cv-qte">
            Qté <br /> {currentOffer.quantity} / 1000
          </div>
        </div>
        <div />
        <div className={`lign-color-${currentOffer.vintage.wine.color}`} />
      </div>
      <div className="lign-border" />
    </div>
  );
};

Card.propTypes = {
  currentOffer: PropTypes.object.isRequired,
};

export default Card;
