import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import './invoice-row.css';

/**
 * just check if param are return from db
 * @param {string} sent
 * @param {string} paidDate
 */

const checkIfSent = (sent, paidDate) => {
  if (sent && paidDate) {
    return 'Envoyé';
  }
  if (sent && !paidDate) {
    return 'Payée';
  }

  return 'Non payée';
};

const InvoiceRow = ({ item }) => (
  <Fragment key={item.uid}>
    <tr key={item.uid} className="tr-admin">
      <td>
        <div className="td-close" />
      </td>
      <td className="td-iv-ref">{item.ref}</td>
      <td>
        <div className="td-close" />
      </td>
      <td className="td-iv-bord">{item.bord}</td>
      <td>
        <div className="td-close" />
      </td>
      <td className="td-iv-price">{item.price} €</td>
      <td>
        <div className="td-close" />
      </td>
      <td className="td-iv-stat">{checkIfSent(item.sent, item.paidDate)}</td>
      <td>
        <div className="td-close" />
      </td>
      <td className="td-iv-tel">
        <i className="icon-download" />
      </td>
      <td>
        <div className="td-close" />
      </td>
    </tr>
  </Fragment>
);

InvoiceRow.propTypes = {
  item: PropTypes.object.isRequired,
};

export default InvoiceRow;
