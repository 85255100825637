import React, { useContext, useEffect } from 'react';
import { useLazyQuery } from 'react-apollo';
import history from '../../../../../../history';
import { LIST_ALLOCATIONS, COUNT_ALLOCATIONS } from '../../../store/requests';
import { StoreContext } from '../../../../../../stores';
import VintageTable from '../vintage-table';
import './allocations-chateau-details.css';
import AllocationsChateauDetailsFilters from '../allocations-chateau-details-row/allocations-chateau-details-filters';
import AllocationsChateauDetailsRow from '../allocations-chateau-details-row';
import AllocationsChateauDetailsPagination from '../allocations-chateau-details-pagination';

const AllocationsChateauDetails = () => {
  const [state, dispatch] = useContext(StoreContext);

  const [
    listAllocations,
    { data, fetchMore: fetchMoreAllocationsChateau },
  ] = useLazyQuery(LIST_ALLOCATIONS, {
    onCompleted: () => {
      dispatch({
        type: 'setState',
        payload: {
          key: 'allocationsChateau',
          value: data.filteredAllocationsFromChateau,
        },
      });
    },
  });

  const [countAllocations, { data: countData }] = useLazyQuery(
    COUNT_ALLOCATIONS,
    {
      onCompleted: () => {
        dispatch({
          type: 'setState',
          payload: {
            key: 'totalAllocationsChateau',
            value: countData.paginatedAllocationsFromChateau,
          },
        });
      },
    },
  );

  const handleClick = () => {
    // eslint-disable-next-line no-undef
    history.push('/allocations');
  };

  useEffect(() => {
    listAllocations({
      variables: {
        vintageUid: state.vintageSelected && state.vintageSelected.uid,
        filter: { sliceNumber: 0, traderName: '' },
      },
    });
    countAllocations({
      variables: {
        vintageUid: state.vintageSelected && state.vintageSelected.uid,
        filter: { sliceNumber: 0, traderName: '' },
      },
    });
  }, []);

  if (!state.vintageSelected)
    return (
      <div>
        {' '}
        <button onClick={handleClick} type="button">
          Liste des vins
        </button>
        <p>Selectionner un vin.</p>
      </div>
    );

  return (
    <div>
      <button onClick={handleClick} type="button">
        Liste des vins
      </button>
      <VintageTable vintage={state.vintageSelected} />
      <div className="row">
        <AllocationsChateauDetailsFilters
          countAllocations={countAllocations}
          listAllocations={listAllocations}
        />
        <AllocationsChateauDetailsPagination
          fetchMoreAllocationsChateau={fetchMoreAllocationsChateau}
          state={state}
          dispatch={dispatch}
        />
      </div>
      {!data ? (
        <div>Loading...</div>
      ) : (
        state.allocationsChateau.map((alloc, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={alloc.uid + index}>
            <AllocationsChateauDetailsRow item={alloc} />
          </div>
        ))
      )}
    </div>
  );
};

export default AllocationsChateauDetails;
