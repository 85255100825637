import gql from 'graphql-tag';

export const LIST_OFFERS = gql`
  query filteredOffers(
    $page: Int! = 1
    $text: String!
    $color: String!
    $year: String!
    $aoc: String!
    $minPrice: Float! = 0
    $maxPrice: Float! = 10000
    $minQuantity: Float! = 0
    $maxQuantity: Float! = 10000
    $ranking: String!
    $format: String!
    $note: String!
    $packaging: String!
    $startTime: Int
    $minNote: Int
    $maxNote: Int
    $endTime: Int
  ) {
    filteredOffers(
      text: $text
      page: $page
      itemsOnPage: 10
      color: $color
      year: $year
      aoc: $aoc
      minPrice: $minPrice
      maxPrice: $maxPrice
      minQuantity: $minQuantity
      maxQuantity: $maxQuantity
      ranking: $ranking
      format: $format
      note: $note
      packaging: $packaging
      startTime: $startTime
      endTime: $endTime
      minNote: $minNote
      maxNote: $maxNote
      reviewerName: $note
    ) {
      uid
      quantity
      comment
      packaging
      format
      price
      date
      regie
      cart {
        uid
      }
      partner {
        name
      }
      vintage {
        uid
        notes {
          min
          max
          reviewer {
            name
          }
        }
        ranking {
          name
        }
        year
        appellation {
          name
        }
        wine {
          name
          color
        }
        slices {
          number
          releasePrice
        }
      }
    }
  }
`;

export const GET_TOTAL_OFFERS = gql`
  query paginatedOffers(
    $text: String!
    $color: String!
    $year: String!
    $aoc: String!
    $minPrice: Float! = 0
    $maxPrice: Float! = 10000
    $minQuantity: Float! = 0
    $maxQuantity: Float! = 10000
    $ranking: String!
    $format: String!
    $note: String!
    $packaging: String!
    $startTime: Int
    $minNote: Int
    $maxNote: Int
    $endTime: Int
  ) {
    paginatedOffers(
      text: $text
      color: $color
      year: $year
      aoc: $aoc
      minPrice: $minPrice
      maxPrice: $maxPrice
      minQuantity: $minQuantity
      maxQuantity: $maxQuantity
      ranking: $ranking
      format: $format
      note: $note
      packaging: $packaging
      startTime: $startTime
      endTime: $endTime
      minNote: $minNote
      maxNote: $maxNote
      reviewerName: $note
    )
  }
`;
