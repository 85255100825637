import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from '../../../theme/input';
import Grid from '../../../theme/grid';
import ButtonPage from '../../../theme/button-page';
import './offer-pagination.css';
import PaginationButton from '../../../theme/pagination';

const OffersPagination = ({ state, dispatch, fetchMoreOffers }) => {
  const pageRef = React.createRef();
  const [maxPage, setMaxPage] = useState(10);
  /**
   * fetch more data from current page
   *
   * @param {Int} value
   */
  const loadOffersAction = async value => {
    const {
      data: { filteredOffers },
    } = await fetchMoreOffers({
      variables: {
        page: value,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return fetchMoreResult;
      },
    });

    dispatch({
      type: 'setState',
      payload: {
        key: 'offers',
        value: filteredOffers,
      },
    });
  };

  /**
   * Handle the click of page button
   *
   * @param {Object} e
   */
  const handleClick = e => {
    const value = parseInt(e.target.value, 10);
    dispatch({
      type: 'setOffersFilters',
      payload: {
        key: 'page',
        value,
      },
    });
    loadOffersAction(value);
  };

  // call the page value in the input
  const handleClickText = async () => {
    if (!pageRef.current.value) {
      pageRef.current.value = 1;
    }
    loadOffersAction(parseInt(pageRef.current.value, 10));
  };

  return (
    <Grid container>
      <Grid size="11">
        <div className="page-div">
          <span className="page">Page</span>{' '}
          <Input
            id="page"
            type="number"
            label="page"
            ref={pageRef}
            defaultValue={pageRef.current}
          />{' '}
          <ButtonPage onClick={handleClickText} />
          <div className="page-row">
            <PaginationButton
              handleClick={handleClick}
              currPage={state.offersFilters.page}
              numberOfItem={state.totalOffers}
              maxPage={maxPage}
              setMaxPage={setMaxPage}
            />
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

OffersPagination.propTypes = {
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  fetchMoreOffers: PropTypes.func,
};

OffersPagination.defaultProps = {
  fetchMoreOffers: () => {},
};

export default OffersPagination;
