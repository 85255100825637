import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from '../../../../theme/input';
import Grid from '../../../../theme/grid';
import ButtonPage from '../../../../theme/button-page';
import PaginationButton from '../../../../theme/pagination';

const InvoicePagination = ({ state, dispatch, fetchMoreInvoice }) => {
  const pageRef = React.createRef();
  const [maxPage, setMaxPage] = useState(10);

  /**
   * fetch more data from current page
   *
   * @param {Int} value
   */
  const loadOffersAction = async value => {
    const {
      data: { paginatedInvoices },
    } = await fetchMoreInvoice({
      variables: {
        page: value,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return fetchMoreResult;
      },
    });
    dispatch({
      type: 'setState',
      payload: {
        key: 'invoice',
        value: paginatedInvoices.invoices,
      },
    });
  };

  /**
   * Handle the click of page button
   *
   * @param {Object} e
   */
  const handleClick = e => {
    const value = parseInt(e.target.value, 10);
    dispatch({
      type: 'setInvoiceFilter',
      payload: {
        key: 'page',
        value,
      },
    });
    loadOffersAction(value);
  };

  const handleClickText = async () => {
    if (!pageRef.current.value) {
      pageRef.current.value = 1;
    }
    loadOffersAction(parseInt(pageRef.current.value, 10));
  };

  return (
    <Grid container>
      <div className="margin-inv" />
      <span className="span-page">Page </span>{' '}
      <Input
        id="page"
        type="number"
        label="page"
        ref={pageRef}
        value={pageRef.current}
      />{' '}
      <ButtonPage onClick={handleClickText} />
      <div className="page-row">
        <PaginationButton
          handleClick={handleClick}
          numberOfItem={state.numberOfInvoice}
          currPage={state.invoiceFilter.page}
          maxPage={maxPage}
          setMaxPage={setMaxPage}
        />
      </div>
    </Grid>
  );
};

InvoicePagination.propTypes = {
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  fetchMoreInvoice: PropTypes.func.isRequired,
};

export default InvoicePagination;
