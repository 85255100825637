import React from 'react';
import PropTypes from 'prop-types';
import { Router, Route, Switch } from 'react-router-dom';

import history from '../history';
import Dashboard from './dashboard';
import Offers from './offers';
import Allocations from './allocations';
import Wines from './wines';
import Admin from './admin';
import Login from './authentication/login';
import Logout from './authentication/logout';
import AppTheme from './app-theme';
import AuthenticationLayout from './layouts/authentication-layout';
import MainLayout from './layouts/main-layout';
import NotFound from './layouts/not-found';
import AllocationsChateauDetails from './allocations/allocations-chateau/allocations-chateau-list/alloctions-chateau-details';

/**
 * A component to custom a Route
 *
 * @param component the component that should be used with the Route
 * @param layout the wrapper of the component
 * @param layoutParams props passed to the Layout component
 * @param rest the rest of the params will be passed as props of Component
 */
const RouteComponent = ({
  component: Component,
  layout: Layout,
  layoutParams,
  ...rest
}) => (
  <Route
    {...rest}
    render={({ match, ...props }) => (
      <Layout {...{ ...layoutParams, match }}>
        <Component {...props} />
      </Layout>
    )}
  />
);

RouteComponent.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  layout: PropTypes.object.isRequired,
  layoutParams: PropTypes.object,
};

RouteComponent.defaultProps = {
  layoutParams: {},
};

export default () => (
  <AppTheme>
    <Router history={history}>
      <Switch>
        <RouteComponent
          exact
          path="/login"
          component={Login}
          layout={AuthenticationLayout}
        />
        <RouteComponent
          exact
          path="/logout"
          component={Logout}
          layout={AuthenticationLayout}
        />
        <RouteComponent
          exact
          path="/"
          component={Dashboard}
          layout={MainLayout}
        />
        <RouteComponent
          exact
          path="/dashboard"
          component={Dashboard}
          layout={MainLayout}
        />
        <RouteComponent
          exact
          path="/offers"
          component={Offers}
          layout={MainLayout}
        />
        <RouteComponent
          exact
          path="/allocations"
          component={Allocations}
          layout={MainLayout}
        />
        <RouteComponent
          exact
          path="/administrative"
          component={Admin}
          layout={MainLayout}
        />
        <RouteComponent
          exact
          path="/wines"
          component={Wines}
          layout={MainLayout}
        />
        <RouteComponent
          exact
          component={AllocationsChateauDetails}
          path="/allocations/detail"
          layout={MainLayout}
        />
        <Route component={NotFound} />
      </Switch>
    </Router>
  </AppTheme>
);
