import gql from 'graphql-tag';

export const LIST_SELECTED_OFFERS = gql`
  query partner($uid: ID!) {
    Partner(uid: $uid) {
      code
      cart {
        uid
        offers {
          date
          packaging
          format
          uid
          quantity
          price
          vintage {
            year
            wine {
              name
              color
            }
          }
        }
      }
    }
  }
`;

export const DELETE_SELECTED_OFFERS = gql`
  mutation deleteToCart($offerUid: String!, $partnerUid: String!) {
    deleteToCart(offerUid: $offerUid, partnerUid: $partnerUid) {
      offers {
        uid
      }
    }
  }
`;
