import React from 'react';
import PropTypes from 'prop-types';
import './invoice-header.css';

const OperationHeader = ({ headers }) => (
  <tr className="tr-inv-head">
    {headers.map((header, index) => (
      <>
        <th className={`th-inv-${header}`} key={header}>
          {header}
        </th>
        <td key={`${header} td`}>
          {index !== headers.length - 1 && <div className="td-close-head" />}{' '}
          {/* to don't have two closing tag */}
        </td>
      </>
    ))}
  </tr>
);

export default OperationHeader;

OperationHeader.propTypes = {
  headers: PropTypes.array.isRequired,
};
