import React, { useContext, useEffect } from 'react';
import { useLazyQuery } from 'react-apollo';
import { LIST_VINTAGES } from '../store/requests';
import { StoreContext } from '../../../../stores';
import AllocationsChateauVintageList from '../allocations-chateau-list';

const AllocationsChateau = () => {
  const [state, dispatch] = useContext(StoreContext);

  const [listVintage] = useLazyQuery(LIST_VINTAGES, {
    onCompleted: ({ vintagesFromChateau }) => {
      dispatch({
        type: 'setState',
        payload: {
          key: 'vintageFromChateau',
          value: vintagesFromChateau,
        },
      });
    },
  });
  useEffect(() => {
    listVintage({
      variables: {
        chateauUid: state.user.partner.properties.uid,
      },
    });
  }, []);

  return (
    <div>
      <AllocationsChateauVintageList state={state} dispatch={dispatch} />
    </div>
  );
};

export default AllocationsChateau;
