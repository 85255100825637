import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import './offers-row.css';
import { wineTypes } from '../../../../wines/config';
import { StoreContext } from '../../../../../stores';
import { timeStampToString } from '../../config';

const OffersRow = ({ item, handleDisplayDetails }) => {
  const [state] = useContext(StoreContext);
  // transform color from db into color for icomoon css
  const color = wineTypes[item.vintage.wine.color];
  const select = item.cart ? 'black' : 'grey'; // check if a cart is link with an offer

  return (
    <>
      <tr className="first-tr">
        <td className={`td-${select}`}>
          <span className="span-round">...</span> {timeStampToString(item.date)}
          {select === 'black' && (
            <>
              {' '}
              <div className="div-selected" />
              <i className="icon-inf_cv" />{' '}
            </>
          )}
        </td>
      </tr>
      <tr className="tr-row" key={`head-row-${item.uid}`}>
        <>
          <td>
            <div className="td-closing" />
          </td>
          <td className="td-div-icon">
            <i className={`icon-${color}`} />{' '}
          </td>
          <td className="td-div-name">
            <div>
              {item.vintage.wine.name}
              <br />
              {item.vintage.year}
            </div>
          </td>
          <td>
            <div className="border-div" />
          </td>
          <td className="td-div-cond">
            Conditionnement
            <br />
            {item.packaging}
          </td>
          <td>
            <div className="border-div" />
          </td>
          <td className="td-div-prix" value="Prix unité">
            Prix unité
            <br />
            {item.price} €
          </td>
          <td className="td-div-margin-price" value="Prix unité">
            Avec marge
            <br />
            {Math.round(
              item.price + item.price * (state.offersFilters.marginApply / 100),
            )}{' '}
            €
          </td>
          <td className="td-div">
            CV
            <br />
            {item.CV}
          </td>
          <td className="td-div">
            PS
            <br />
            {item.PS} €
          </td>
          <td className="td-div">
            PRC
            <br />
            {item.PRC} €
          </td>
          <td>
            <div className="border-div" />
          </td>
          <td className="td-div-quant">
            Quantité
            <br />
            <strong>{item.Quantité}</strong>
          </td>
          <td>
            <div className="border-div" />
          </td>
          <td className="td-div-reg">
            Régie
            <br />
            {item.Régie}
          </td>
          <td>
            <div className="border-div" />
          </td>
          <td className="td-div-button">
            <button
              className="button-select"
              type="button"
              onClick={handleDisplayDetails}
            >
              Selectionner cette offre
            </button>
          </td>
          <td>
            <div className="td-closing-right" />
          </td>
        </>
      </tr>
      <tr className="width-98">
        <td className="width-100">
          <div className={`div-lign-${item.vintage.wine.color}`} />
          <div className="margin" />
        </td>
      </tr>
    </>
  );
};

OffersRow.propTypes = {
  item: PropTypes.object.isRequired,
  handleDisplayDetails: PropTypes.func.isRequired,
};

export default OffersRow;
