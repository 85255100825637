import { red } from '@material-ui/core/colors';

const configFontSize = {
  xs: 11,
  sm: 14,
  md: 14,
  lg: 14,
  xl: 20,
};

const configBody2FontSize = {
  xs: '0.9rem',
  sm: '0.9rem',
};

export default width => ({
  palette: {
    primary: {
      ...red,
      main: '#a8253e',
    },
    secondary: {
      ...red,
      main: '#ff8c98',
    },
    others: {
      black: '#101010',
      lighGrey: '#f0f0f5',
      mediumGrey: '#dcdcdc',
      pink: '#ab233b',
      yellow: '#ffdd4f',
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: ['Montserrat-Regular', 'Roboto', 'sans-serif'].join(','),
    fontSize: configFontSize[width],
    body2: {
      fontSize: configBody2FontSize[width],
    },
  },
});
