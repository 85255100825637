import React from 'react';
import './grid.css';
import PropTypes from 'prop-types';

export const Grid = ({ size, container = false, children }) => {
  if (container) {
    return <div className="section">{children}</div>;
  }

  return <div className={`col col-${size}`}>{children}</div>;
};

Grid.defaultProps = {
  container: false,
  size: '',
  children: [],
};

Grid.propTypes = {
  size: PropTypes.string,
  container: PropTypes.bool,
  children: PropTypes.any,
};
