import React from 'react';
import PropTypes from 'prop-types';

import { CircularProgress } from '@material-ui/core';

/**
 * Loader, display a circular progress
 *
 * @param style
 */
const Loader = ({ style }) => (
  <div style={style}>
    <CircularProgress size={50} style={{ color: '#000' }} />
  </div>
);

Loader.propTypes = {
  style: PropTypes.object,
};

Loader.defaultProps = {
  style: {},
};

export default Loader;
