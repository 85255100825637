import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import './operation-row.css';
import { toDate } from '../../../../config';
import '../../../../../app-theme/fonts/icomoon/style.css';

const OperationRow = ({ item, handleDisplayDetails }) => (
  <Fragment key={item.uid}>
    <tr key={item.uid} className="tr-admin">
      <td>
        <div className="td-close" />
      </td>
      <td className="td-admin-ref">{item.number}</td>
      <td>
        <div className="td-close" />
      </td>
      <td className="td-admin-buyer">{item.buyerPartner}</td>
      <td>
        <div className="td-close" />
      </td>
      <td className="td-admin-sales">{item.salesPartner}</td>
      <td>
        <div className="td-close" />
      </td>
      <td className="td-admin-date">{toDate(item.date)}</td>
      <td>
        <div className="td-close" />
      </td>
      <td className="td-admin-price">{item.price} €</td>
      <td>
        <div className="td-close" />
      </td>
      {/* eslint-disable */}
      <td className="span-round-op" onClick={handleDisplayDetails}>
        ...
      </td>
      <td>
        <div className="td-close" />
      </td>
      {/* eslint-enable */}
      <td className="td-admin">
        <i className="icon-download" />
      </td>
      <td>
        <div className="td-close" />
      </td>
    </tr>
  </Fragment>
);

OperationRow.propTypes = {
  item: PropTypes.object.isRequired,
  handleDisplayDetails: PropTypes.func.isRequired,
};

export default OperationRow;
