export default theme => ({
  wrapper: {
    position: 'absolute',
    bottom: 0,
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#FFF',
    width: '100%',
    height: '10rem',
  },
  headerWrapper: {
    position: 'relative',
    '&::before': {
      content: "''",
      position: 'absolute',
      bottom: '-0.5rem',
      width: '80%',
      marginLeft: '10%',
      borderBottom: '1px solid #e6e6e6',
    },
  },
  header: {
    position: 'relative',
    fontFamily: 'CormorantGaramond-Regular',
    fontVariant: 'small-caps',
    fontSize: '1.4rem',
    textAlign: 'center',
  },
  address: {
    marginTop: '1rem',
  },
  text: {
    color: '#FFF',
    lineHeight: '1.15rem',
    fontSize: '0.75rem',
  },
  icon: {
    fontSize: '0.75rem',
  },
});
