import React from 'react';
import './input.css';
import PropTypes from 'prop-types';

export const Input = React.forwardRef(
  ({ value, type, onChange, id, label, placeholder, disabled }, ref) => (
    <div className="search">
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        className="input_styled"
        onChange={onChange}
        id={id}
        label={label}
        ref={ref}
        disabled={disabled}
      />
    </div>
  ),
);

Input.defaultProps = {
  type: '',
  placeholder: '',
  value: '',
  onChange: () => {},
  label: '',
  id: '',
  disabled: '',
};

Input.propTypes = {
  value: PropTypes.any,
  type: PropTypes.string,
  onChange: PropTypes.func,
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.string,
};
