import React, { useContext } from 'react';
import AllocationsClient from '../allocations-client';
import AllocationsChateau from '../allocations-chateau';
import { StoreContext } from '../../../stores';

/* If a Trader is loggin AllocationsClient is display to let him set a quantity to an allocation, but if it's a Chateau, 
AllocationChateau is display to let him see who is asking for his wine */

const Allocations = () => {
  const [state] = useContext(StoreContext);

  return (
    <>
      {state.user.partner.labels[0] === 'Chateau' ? (
        <AllocationsChateau />
      ) : (
        <AllocationsClient />
      )}
    </>
  );
};

export default Allocations;
