import React from 'react';
import PropTypes from 'prop-types';

import noImage from '../no-image.png';

const Avatar = ({ classes, height, width, src }) => (
  <img
    src={src.length === 0 ? noImage : src}
    className={classes.image}
    height={height}
    width={width}
    alt="avatar"
  />
);

Avatar.propTypes = {
  classes: PropTypes.object.isRequired,
  src: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

Avatar.defaultProps = {
  src: '',
  height: 150,
  width: 150,
};

export default Avatar;
