export default theme => ({
  wrapper: {
    position: 'relative',
    backgroundColor: theme.palette.others.black,
    height: '100%',
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    padding: '3rem 1rem',
  },
  logo: {
    height: '8rem',
  },
  searchWrapper: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: '1.3rem',
    paddingRight: '1.3rem',
    paddingBottom: '2rem',
    '&::before': {
      content: "''",
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      borderBottom: '1px solid #8c8c8c',
    },
  },
  search: {
    backgroundColor: '#fff',
    borderRadius: '50px',
    padding: '0rem 1rem',
  },
});
