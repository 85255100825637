import React, { useContext, useEffect } from 'react';
import { useLazyQuery, useMutation } from 'react-apollo';
import {
  LIST_ALLOCATIONS,
  COUNT_ALLOCATION,
  SET_QUANTITY,
} from '../store/allocations/requests';
import { StoreContext } from '../../../../stores';
import AllocationsClientFilters from '../allocations-client-filters';
import AllocationsClientList from '../allocations-client-list';
import './allocation.css';
import AllocationsClientPagination from '../allocations-client-pagination';

const AllocationsClient = () => {
  const [state, dispatch] = useContext(StoreContext);

  const [setQuantity] = useMutation(SET_QUANTITY);

  const [countAllocations] = useLazyQuery(COUNT_ALLOCATION, {
    onCompleted: ({ paginatedAllocations }) => {
      dispatch({
        type: 'setState',
        payload: {
          key: 'totalAllocations',
          value: paginatedAllocations,
        },
      });
    },
  });

  const [
    listAllocations,
    { loading, fetchMore: fetchMoreAllocations },
  ] = useLazyQuery(LIST_ALLOCATIONS, {
    onCompleted: ({ filteredAllocations }) => {
      dispatch({
        type: 'setState',
        payload: {
          key: 'allocations',
          value: filteredAllocations,
        },
      });
    },
  });

  useEffect(() => {
    listAllocations({
      variables: {
        page: state.allocationsFilters.page,
        filter: state.allocationsFilters,
      },
    });
    countAllocations({ variables: { filter: state.allocationsFilters } });
  }, []);

  return (
    <div className="column-alloc">
      PRIMEURS <br />
      <div className="row-alloc">
        <AllocationsClientFilters
          state={state}
          dispatch={dispatch}
          listAllocations={listAllocations}
          countAllocations={countAllocations}
        />
        <AllocationsClientPagination
          state={state}
          dispatch={dispatch}
          fetchMoreAllocations={fetchMoreAllocations}
        />
      </div>
      {loading ? (
        <p>loading</p>
      ) : (
        <AllocationsClientList state={state} setQuantity={setQuantity} />
      )}
    </div>
  );
};

export default AllocationsClient;
