export default [
  {
    name: 'Mon dashboard',
    match: ['', '/dashboard'],
    link: '/dashboard',
  },
  {
    name: 'Offres',
    match: ['/offers'],
    link: '/offers',
  },
  {
    name: 'Primeurs',
    match: ['/allocations'],
    link: '/allocations',
  },
  {
    name: 'Administratif',
    match: ['/administrative'],
    link: '/administrative',
  },
  {
    name: 'Vins',
    match: ['/wines'],
    link: '/wines',
  },
];
