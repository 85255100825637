import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { InputWine, Input } from '../../../theme/input';
import Select from '../../../theme/select';
import Grid from '../../../theme/grid';
import FilterButton from '../../../theme/filter-button';
import { colors } from '../../../offers/offers-filters/config';

export const WinesFilters = ({ listWines, state, dispatch }) => {
  const [disabled, setDisabled] = useState('disabled');

  // load new offers with filters
  const loadWinesAction = () => {
    listWines({
      variables: {
        filter: state.wineFilters,
        page: state.wineFilters.page,
      },
    });
  };

  /**
   * set wine filters
   * @param {string} key
   */
  const handleOnChange = key => e => {
    let { value } = e.target;
    if (e.target.type === 'number') {
      value = parseInt(value, 10);
    }
    dispatch({
      type: 'setWinesFilters',
      payload: {
        key,
        value,
      },
    });
  };

  // access on note input only when input of name author is filled
  if (state.wineFilters.reviewerCode && disabled === 'disabled')
    setDisabled('');
  // disable access when no input in authour name and disabled is false
  if (!state.wineFilters.reviewerCode && disabled === '')
    setDisabled('disabled');

  return (
    <Grid container>
      <Grid size="12">
        <span className="wine">Vin</span>
        <InputWine
          type=""
          id="wine"
          label="Vin"
          onChange={handleOnChange('wineName')}
          value={state.wineFilters.wineName}
        />
        <Select onChange={handleOnChange('colorCode')} optn={colors} />
        <Input
          placeholder="Millésime"
          id="date"
          type="number"
          label="milésime"
          value={state.wineFilters.vintageYear}
          onChange={handleOnChange('vintageYear')}
        />
        <Input
          type=""
          placeholder="AOC"
          value={state.wineFilters.appellationCode}
          id="AOC"
          label="appellation"
          onChange={handleOnChange('appellationCode')}
        />
        <Input
          placeholder="Classement"
          id="ranking"
          type=""
          label="ranking"
          value={state.wineFilters.rankingCode}
          onChange={handleOnChange('rankingCode')}
        />
        <Input
          placeholder="Nom de l'auteur"
          id="note"
          type=""
          label="note"
          value={state.wineFilters.reviewerCode}
          onChange={handleOnChange('reviewerCode')}
        />
        <Input
          placeholder="Note max"
          type="number"
          value={state.wineFilters.maxNote}
          disabled={disabled}
        />
        <Input
          placeholder="Note min"
          type="number"
          value={state.wineFilters.maxNote}
          disabled={disabled}
        />
      </Grid>
      <div className="row-wine">
        <div className="div-lign-but" />
        <FilterButton onClick={loadWinesAction}>
          Mettre à jour les filtres
        </FilterButton>
      </div>
    </Grid>
  );
};

WinesFilters.propTypes = {
  listWines: PropTypes.any.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};
