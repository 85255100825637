export const winesInitialState = {
  wineFilters: {
    page: 1,
    wineName: undefined,
    colorCode: undefined,
    vintageYear: undefined,
    appellationCode: undefined,
    rankingCode: undefined,
    reviewerCode: undefined,
    minNote: undefined,
    maxNote: undefined,
  },
  wines: undefined,
  numberOfVintage: undefined,
};

export const winesReducer = (state, { type, payload = {} }) => {
  switch (type) {
    case 'setWinesFilters':
      return {
        ...state,
        wineFilters: { ...state.wineFilters, [payload.key]: payload.value },
      };
    default:
      return state;
  }
};
