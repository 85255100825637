export const allocationsInitialState = {
  allocations: [],
  totalAllocations: 0,
  allocationsFilters: {
    year: null,
    wineName: '',
    sliceNumber: 1,
    page: 1,
  },
  vintageFromChateau: [],
  allocationsChateauFilters: {
    traderName: '',
    page: 1,
    sliceNumber: '',
  },
  allocationsChateau: [],
  totalAllocationsChateau: 0,
  vintageSelected: undefined,
};

export const allocationsReducer = (state, { type, payload = {} }) => {
  switch (type) {
    case 'setAllocationsFilters':
      return {
        ...state,
        allocationsFilters: {
          ...state.allocationsFilters,
          [payload.key]: payload.value,
        },
      };
    case 'setAllocationsChateauFilters':
      return {
        ...state,
        allocationsChateauFilters: {
          ...state.allocationsChateauFilters,
          [payload.key]: payload.value,
        },
      };
    default:
      return state;
  }
};
