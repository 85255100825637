import React from 'react';
import Invoice from '../invoice';
import Operation from '../operation';
import './admin.css';

const Admin = () => (
  <div>
    <div>
      <span className="span-admin">ADMINISTRATIF</span>
      <div className="div-sep" />
    </div>
    <div className="div-admin">
      <Operation />
      <Invoice />
    </div>
  </div>
);

export default Admin;
