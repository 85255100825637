import React, { useContext, useEffect } from 'react';
import { useLazyQuery } from 'react-apollo';
import OffersFilters from '../offers-filters';
import OffersList from '../offers-list';
import OffersPagination from '../offers-pagination';
import {
  LIST_OFFERS,
  GET_TOTAL_OFFERS,
} from '../store/offers-pagination/request';
import './offers.css';
import { StoreContext } from '../../../stores';

const Offers = () => {
  const [state, dispatch] = useContext(StoreContext);

  // get the number of offers returned by db
  const [getTotalOffers] = useLazyQuery(GET_TOTAL_OFFERS, {
    onCompleted: ({ paginatedOffers }) => {
      dispatch({
        type: 'setTotalOffers',
        payload: {
          key: 'totalOffers',
          value: paginatedOffers,
        },
      });
    },
  });

  // fetch offers
  const [listOffers, { loading, fetchMore: fetchMoreOffers }] = useLazyQuery(
    LIST_OFFERS,
    {
      onCompleted: ({ filteredOffers }) => {
        dispatch({
          type: 'setState',
          payload: {
            key: 'offers',
            value: filteredOffers,
          },
        });
      },
    },
  );

  useEffect(() => {
    getTotalOffers({ variables: state.offersFilters });
    listOffers({ variables: state.offersFilters });
  }, []);

  return (
    <div className="div-offer">
      <div className="search-div">RECHERCHE</div>
      <div className="div-offer-filter">
        <OffersFilters
          state={state}
          dispatch={dispatch}
          listOffers={listOffers}
          getTotalOffers={getTotalOffers}
        />
      </div>
      <div>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            <OffersPagination
              state={state}
              fetchMoreOffers={fetchMoreOffers}
              dispatch={dispatch}
            />
            <OffersList state={state} />
          </>
        )}
      </div>
    </div>
  );
};

export default Offers;
