import gql from 'graphql-tag';

export const LIST_ALLOCATIONS = gql`
  query filteredAllocations($page: Int, $filter: JSON) {
    filteredAllocations(page: $page, filter: $filter) {
      uid
      date
      demandedQuantity
      confirmedQuantity
      done
      proposed
      totalConfirmedQuantity
      webQuantity {
        webConfirmedQuantity
      }
      slice {
        vintage {
          uid
          latestSlice {
            recommendedPrice
            releasePrice
          }
          appellation {
            name
          }
          ranking {
            name
          }
          wine {
            name
            color
          }
          year
        }
      }
      previousList {
        uid
        demandedQuantity
        confirmedQuantity
        slice {
          vintage {
            latestSlice {
              recommendedPrice
              releasePrice
            }
          }
        }
      }
    }
  }
`;

export const COUNT_ALLOCATION = gql`
  query paginatedAllocations($filter: JSON) {
    paginatedAllocations(filter: $filter)
  }
`;

export const SET_QUANTITY = gql`
  mutation setWebConfirmedQuantity(
    $webConfirmedQuantity: Int
    $allocationUid: ID!
    $partnerUid: ID!
  ) {
    setWebConfirmedQuantity(
      webConfirmedQuantity: $webConfirmedQuantity
      allocationUid: $allocationUid
      partnerUid: $partnerUid
    )
  }
`;
