import PropTypes from 'prop-types';
import React, { useRef, useContext } from 'react';
import { wineTypes } from '../../../../../wines/config';
import './allocation-row.css';
import { toDate } from '../../../../../admin/config';
import { StoreContext } from '../../../../../../stores';

const AllocationsClientRow = ({ item, setQuantity, handleDisplayDetails }) => {
  const color = wineTypes[item.slice.vintage.wine.color];
  const refQuantity = useRef(0);
  const [state] = useContext(StoreContext);

  const statusToText = (proposed, done) => {
    if (done) {
      return 'Validé';
    }
    if (proposed && !done) {
      return 'En cours';
    }
    return '-';
  };

  const setQuantityConfirmed = () => {
    setQuantity({
      variables: {
        webConfirmedQuantity: parseInt(refQuantity.current.value, 10),
        allocationUid: item.uid,
        partnerUid: state.user.partner.properties.uid,
      },
    });
  };

  return (
    <>
      <tr className="tr-alloc-first">
        <td className="td-grey">
          <span className="span-round">...</span>
          Sortie: {toDate(item.date)}
          <div className="div-selected" />
          <div className="div-close-alloc" />
          Clôture:
          <button type="button" onClick={handleDisplayDetails}>
            Accès bordereaux
          </button>
        </td>
      </tr>
      <tr className="tr-alloc">
        <td>
          <div className="td-closing-alloc" />
        </td>
        <td className="td-div-alloc-name">
          {' '}
          <div className="i-alloc">
            <i className={`icon-${color}`} />
          </div>
          {item.slice.vintage.wine.name}
        </td>
        <td className="td-div-alloc-close">
          <div className="div-close-alloc" />
        </td>
        <td className="td-div-alloc-form">
          format
          <br />
          {item.format}
        </td>
        <td className="td-div-alloc-close">
          <div className="div-close-alloc" />
        </td>
        <td className="td-div-alloc">
          PS
          <br />
          {item.slice.vintage.latestSlice.releasePrice}
        </td>
        <td className="td-div-alloc">
          PRC
          <br />
          {item.slice.vintage.latestSlice.recommendedPrice}
        </td>
        <td className="td-div-alloc">
          PRD (HT)
          <br />
        </td>
        <td className="td-div-alloc-close">
          <div className="div-close-alloc" />
        </td>
        <td className="td-div-alloc">
          Qté Offerte <br />
          {item.demandedQuantity}
        </td>
        <td className="td-div-alloc-qtt-conf">
          Qté confirmée <br />{' '}
          <input
            defaultValue={item.webQuantity.webConfirmedQuantity}
            type="number"
            ref={refQuantity}
            className="input-qtt-conf"
          />{' '}
          <button type="button" onClick={setQuantityConfirmed} />
        </td>
        <td className="td-div-alloc">
          Statut <br /> {statusToText(item.proposed, item.done)}
        </td>
        <td className="td-div-alloc">
          <div className="td-closing-alloc" />
        </td>
      </tr>
      <tr className="tr-alloc">
        <td>
          <div className="td-closing-alloc" />
        </td>
        <td className="td-div-alloc-aoc">
          AOC <br />{' '}
          {item.slice.vintage.appellation &&
            item.slice.vintage.appellation.name}
        </td>
        <td className="td-div-alloc-class">
          Classement <br />{' '}
          {item.slice.vintage.ranking && item.slice.vintage.ranking.name}
        </td>
        <td className="td-div-alloc-close">
          <div className="div-close-alloc" />
        </td>
        <td className="td-div-alloc-cond">
          Conditionnement <br /> {item.packaging}
        </td>
        <td className="td-div-alloc-close">
          <div className="div-close-alloc" />
        </td>
        <td className="td-div-alloc">
          PS n-1 <br />{' '}
          {item.previousList.latestSlice &&
            item.previousList.latestSlice.releasePrice}
        </td>
        <td className="td-div-alloc">
          PRC n-1 <br />{' '}
          {item.previousList.latestSlice &&
            item.previousList.latestSlice.recommendedPrice}
        </td>
        <td className="td-div-alloc">
          PRD (HT) n-1 <br />
        </td>
        <td className="td-div-alloc-close">
          <div className="div-close-alloc" />
        </td>
        <td className="td-div-alloc">
          Offre n-1 <br />{' '}
          {item.previousList && item.previousList.demandedQuantity}
        </td>
        <td className="td-div-conf-1">
          Confirmé n-1 <br />{' '}
          {item.previousList && item.previousList.confirmedQuantity}
        </td>
        <td>
          <div className="td-closing-alloc" />
        </td>
      </tr>
      <tr className="width-98">
        <td className="width-100">
          <div className={`div-lign-alloc-${item.slice.vintage.wine.color}`} />
          <div className="margin" />
        </td>
      </tr>
    </>
  );
};

AllocationsClientRow.propTypes = {
  item: PropTypes.any.isRequired,
  setQuantity: PropTypes.func.isRequired,
  handleDisplayDetails: PropTypes.func.isRequired,
};

export default AllocationsClientRow;
