import React, { useEffect, useContext } from 'react';
import { useLazyQuery } from 'react-apollo';
import { StoreContext } from '../../../../stores';
import { LIST_INVOICES } from '../store/requests';
import InvoicePagination from '../invoice-pagination';
import InvoiceList from '../invoice-list';
import './invoice.css';

const Invoice = () => {
  const [state, dispatch] = useContext(StoreContext);

  const [listInvoices, { loading, fetchMore: fetchMoreInvoice }] = useLazyQuery(
    LIST_INVOICES,
    {
      variables: state.invoiceFilter,
      onCompleted: ({ paginatedInvoices }) => {
        dispatch({
          type: 'setState',
          payload: {
            key: 'invoice',
            value: paginatedInvoices.invoices,
          },
        });
        dispatch({
          type: 'setState',
          payload: {
            key: 'numberOfInvoice',
            value: paginatedInvoices.total,
          },
        });
      },
    },
  );

  useEffect(() => {
    listInvoices({ variables: state.invoiceFilter });
  }, []);

  return (
    <div className="div-invoice">
      <span className="inv">FACTURES</span>
      {loading || !state.invoice ? (
        <p>Loading...</p>
      ) : (
        <>
          <InvoicePagination
            state={state}
            dispatch={dispatch}
            fetchMoreInvoice={fetchMoreInvoice}
          />
          <InvoiceList state={state} />
          <div className="div-close" />
        </>
      )}
    </div>
  );
};

export default Invoice;
