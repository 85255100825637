import React from 'react';
import PropTypes from 'prop-types';
import './allocations-details.css';
import { toDate } from '../../../../../admin/config';

const AllocationsClientDetails = ({ item, handleDisplayDetails }) => {
  const handleClick = () => {
    handleDisplayDetails();
  };

  return (
    <div className="popup-alloc">
      <div className="popup-inner-alloc">
        <div>
          Demander une option{' '}
          <button type="button" onClick={handleClick}>
            Fermer
          </button>
        </div>
        <div className="row-popup-alloc">
          <i />
          {item.slice.vintage.wine.name}
          <br />
          {item.slice.vintage.wine.galaxie}
        </div>
        <div className="row-popup-alloc-grey">
          <div>
            Qté demandé <br />
            {item.demandedQuantity}
          </div>
          <div>
            Date <br />
            {toDate(item.date)}
          </div>
          <div>
            <i />
            {item.confirmed}
          </div>
        </div>
      </div>
    </div>
  );
};

AllocationsClientDetails.propTypes = {
  item: PropTypes.object.isRequired,
  handleDisplayDetails: PropTypes.func.isRequired,
};

export default AllocationsClientDetails;
