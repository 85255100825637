import React from 'react';
import './filterButton.css';
import { KeyboardArrowRight } from '@material-ui/icons';
import PropTypes from 'prop-types';

export const FilterButton = ({ onClick, children }) => (
  /* eslint-disable */
  <div onClick={onClick} className="button-filter">
    <span className="span-child">{children}</span>
    <div className="round-filter"><KeyboardArrowRight /></div>
  </div>
  /* eslint-enable */
);

FilterButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
};
