export default theme => ({
  container: {
    backgroundColor: 'black',
    height: '13.5rem',
    width: '27rem',
    backgroundImage: 'black',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: '0 0 25px #A4A4A4',
  },
  form: {
    backgroundColor: 'black',
    width: '100%',
    height: '100%',
    backgroundImage: 'black',
  },
  labelUser: {
    fontFamily: 'Montserrat-Regular',
    color: 'white',
    fontSize: '0.9rem',
    marginTop: '2.5rem',
    textAlign: 'right',
  },
  labelPassword: {
    fontFamily: 'Montserrat-Regular',
    color: 'white',
    fontSize: '0.9rem',
    marginTop: '-0.7rem',
    textAlign: 'right',
  },
  textFieldEmail: {
    marginTop: '1rem',
  },
  textFieldPassword: {
    marginTop: '-2rem',
  },
  input: {
    backgroundColor: 'white',
    borderRadius: '50px',
    fontSize: '1rem',
    marginTop: '1rem',
    marginLeft: '1rem',
    width: '14rem',
    paddingLeft: '1rem',
    textDecoration: 'none',
  },
  buttonWrapper: {
    padding: '7px 10px',
    border: 'none',
    borderRadius: '50px',
    fontSize: '1rem',
    color: 'white',
    width: '10rem',
    marginLeft: '-3.2rem',
    backgroundColor: theme.palette.primary.main,
    fontFamily: 'Montserrat-Regular',
    fontWeight: 'Bolder',
  },
  logo: {
    height: '10rem',
    position: 'absolute',
    left: '50%',
    top: '24%',
    transform: 'translate(-50%, -50%)',
  },
});
