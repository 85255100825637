export default theme => ({
  navLink: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '1.5rem',
    textDecoration: 'none',
    color: '#FFF',
    height: '3.5rem',
    fontSize: '1.65rem',
    fontFamily: 'CormorantGaramond-Regular',
    fontVariant: 'small-caps',
    '&::before': {
      content: "''",
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      borderBottom: '1px solid #8c8c8c',
    },
  },
  logout: {
    width: '100%',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    outline: 'inherit',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '1.5rem',
    textDecoration: 'none',
    color: '#FFF',
    height: '3.5rem',
    fontSize: '1.65rem',
    fontFamily: 'CormorantGaramond-Regular',
    fontVariant: 'small-caps',
    '&::before': {
      content: "''",
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      borderBottom: '1px solid #8c8c8c',
    },
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
  },
});
