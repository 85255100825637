export default theme => ({
  wrapper: {
    paddingBottom: '1rem',
    borderBottom: '2px solid #BDBDBD',
    marginLeft: '1rem',
  },
  text: {
    color: theme.palette.primary.main,
    fontSize: '2rem',
    lineHeight: '110%',
  },
});
