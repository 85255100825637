import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { useLazyQuery } from 'react-apollo';

import { Grid, TextField, Typography } from '@material-ui/core';

import { loginRequest } from '../../store/authentication/requests';

import logo from '../../../layouts/main-layout/menu/images/logo.png';

const Login = ({ classes, cookies, history }) => {
  const email = useRef();
  const password = useRef();

  const [login, { data }] = useLazyQuery(loginRequest);

  if (data && data.login && data.login.data) {
    cookies.set('token', data.login.data);

    const token = cookies.get('token');

    if (R.not(R.isEmpty(token)) && token) {
      history.push('/');
    }
  }

  const handleOnSubmit = async e => {
    e.preventDefault();

    const variables = {
      email: email.current.value,
      password: password.current.value,
    };

    await login({ variables });
  };

  return (
    <form onSubmit={handleOnSubmit} className={classes.form}>
      <Grid item xs={12} className={classes.logoWrapper}>
        <img src={logo} alt="logo" className={classes.logo} />
      </Grid>
      <Grid container className={classes.container}>
        <Grid item xs={4}>
          <Typography className={classes.labelUser}>UTILISATEUR </Typography>
        </Grid>
        <Grid item xs={8} className={classes.textFieldEmail}>
          <TextField
            id="user"
            inputRef={email}
            InputProps={{
              className: classes.input,
              disableUnderline: true,
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.labelPassword}>
            MOT DE PASSE
          </Typography>
        </Grid>
        <Grid item xs={8} className={classes.textFieldPassword}>
          <TextField
            inputRef={password}
            type="password"
            InputProps={{
              className: classes.input,
              disableUnderline: true,
            }}
          />
        </Grid>
        <Grid item xs={8} />
        <Grid item xs={2}>
          <button className={classes.buttonWrapper} type="submit">
            Valider
          </button>
        </Grid>
      </Grid>
    </form>
  );
};

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  cookies: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default Login;
