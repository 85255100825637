import React, { useContext } from 'react';
import { Grid, TextField } from '@material-ui/core';
import { StoreContext } from '../../../../stores';
import Avatar from '../../../utils-components/avatar';

const Profile = () => {
  const [state] = useContext(StoreContext);

  return (
    <Grid container>
      <Grid item xs={12}>
        PROFIL
      </Grid>
      <Grid item xs={2}>
        <Avatar />
      </Grid>
      <Grid item xs={10}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <TextField
              id="firstname"
              label="Prénom"
              defaultValue={state.user.firstName}
              disabled
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="lastname"
              label="Nom"
              defaultValue={state.user.lastName}
              disabled
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="email"
              label="Email"
              defaultValue={state.user.email}
              disabled
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Profile;
