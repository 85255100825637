export const invoiceInitialState = {
  invoiceFilter: {
    page: 1,
  },
  invoice: undefined,
  numberOfInvoice: undefined,
};

export const invoiceReducer = (state, { type, payload = {} }) => {
  switch (type) {
    case 'setInvoiceFilter':
      return {
        ...state,
        invoiceFilter: {
          ...state.invoiceFilter,
          [payload.key]: payload.value,
        },
      };
    default:
      return state;
  }
};
