import React from 'react';
import './buttonPage.css';
import { KeyboardArrowRight } from '@material-ui/icons';
import PropTypes from 'prop-types';

export const ButtonPage = ({ onClick }) => (
  /* eslint-disable */
  <div onClick={onClick} className="button-page">
    <KeyboardArrowRight className="button-page-icon"/>
  </div>
  /* eslint enable */
);

ButtonPage.propTypes = {
  onClick: PropTypes.func.isRequired,
};
