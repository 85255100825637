export const colors = [
  {
    value: 'RO',
    label: 'Rouge',
  },
  {
    value: 'BL',
    label: 'Blanc',
  },
  {
    value: 'Rosé',
    label: 'Rosé',
  },
  {
    value: 'PET',
    label: 'Pétillant',
  },
  {
    value: 'LIQ',
    label: 'Liquoreux',
  },
];
