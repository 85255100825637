import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { StoreContext } from '../../../../../../../../stores';

const AllocationsChateauDetailsFilters = ({
  listAllocations,
  countAllocations,
}) => {
  const [state, disptach] = useContext(StoreContext);

  const onChange = key => e => {
    disptach({
      type: 'setAllocationsChateauFilters',
      payload: {
        key,
        value: e.target.value,
      },
    });
  };

  const loadAllocations = () => {
    listAllocations({
      variables: {
        vintageUid: state.vintageSelected && state.vintageSelected.uid,
        filter: state.allocationsChateauFilters,
      },
    });
    countAllocations({
      variables: {
        vintageUid: state.vintageSelected && state.vintageSelected.uid,
        filter: state.allocationsChateauFilters,
      },
    });
  };

  return (
    <div>
      <select
        onChange={onChange('sliceNumber')}
        value={state.allocationsChateauFilters.sliceNumber}
      >
        <option value={0}>Toutes</option>
        <option value={1}>tranche 1</option>
        <option value={2}>tranche 2</option>
        <option value={3}>tranche 3</option>
      </select>
      Négociant
      <input
        onChange={onChange('traderName')}
        value={state.allocationsChateauFilters.traderName}
      />
      <button onClick={loadAllocations} type="button">
        Mettre à jour les filtres
      </button>
    </div>
  );
};

AllocationsChateauDetailsFilters.propTypes = {
  listAllocations: PropTypes.func.isRequired,
  countAllocations: PropTypes.func.isRequired,
};

export default AllocationsChateauDetailsFilters;
