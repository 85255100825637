import React, { useContext } from 'react';
import { useQuery, useMutation } from 'react-apollo';
import {
  LIST_SELECTED_OFFERS,
  DELETE_SELECTED_OFFERS,
} from '../../store/selected-offers/requests';
import Card from '../card';
import { StoreContext } from '../../../../stores';
import './offers-selected.css';
import Popup from '../../../theme/popup';

const SelectedOffers = () => {
  const [state, dispatch] = useContext(StoreContext);
  const [deleteOffer] = useMutation(DELETE_SELECTED_OFFERS);

  // fetch offers
  const { loading, data, refetch: listSelectedOffers } = useQuery(
    LIST_SELECTED_OFFERS,
    {
      variables: {
        uid: state.user.partner.properties.uid,
      },
    },
  );

  // delete offers in cart
  const handleDeleteOffer = async () => {
    await deleteOffer({
      variables: {
        offerUid: state.currentSelectedOffer.uid,
        partnerUid: state.user.partner.properties.uid,
      },
    });

    listSelectedOffers();

    dispatch({
      type: 'setState',
      payload: {
        key: 'displayDeleteOffer',
        value: false,
      },
    });
  };

  const OffersList = () => (
    <div className="display-two">
      {data.Partner.cart &&
        data.Partner.cart.offers.map(offer => (
          <Card key={offer.uid} currentOffer={offer} />
        ))}
    </div>
  );

  // function for popup
  const onCancel = () => {
    dispatch({
      type: 'setState',
      payload: {
        key: 'displayDeleteOffer',
        value: false,
      },
    });
  };

  return (
    <div>
      <span className="background-red">MES OFFRES SELECTIONNÉES</span>
      <div className="margin-selectoff" />
      {loading ? <p>loading</p> : <OffersList />}
      <Popup
        display={state.displayDeleteOffer}
        onValidate={handleDeleteOffer}
        content="supprimer"
        onCancel={onCancel}
      />
    </div>
  );
};

export default SelectedOffers;
