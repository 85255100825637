import React from 'react';
import PropTypes from 'prop-types';

import { Grid, InputBase, InputAdornment } from '@material-ui/core';
import { Search } from '@material-ui/icons';

import Categories from '../categories';
import FooterMenu from '../footer-menu';

import logo from '../images/logo.png';

const Menu = ({ classes }) => (
  <div className={classes.wrapper}>
    <Grid container>
      <Grid item xs={12} className={classes.logoWrapper}>
        <img src={logo} alt="logo" className={classes.logo} />
      </Grid>
      <Grid item xs={12} className={classes.searchWrapper}>
        <InputBase
          className={classes.search}
          endAdornment={
            <InputAdornment position="end">
              <Search />
            </InputAdornment>
          }
          fullWidth
        />
      </Grid>
      <Categories />
      <FooterMenu />
    </Grid>
  </div>
);

Menu.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default Menu;
