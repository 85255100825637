import React from 'react';
import PropTypes from 'prop-types';
import Table from '../../../../theme/table';
import AllocationsRow from '../allocations-client-row';
import AllocationDetails from '../allocation-client-details';

const keys = ['uid'];

const AllocationsClientList = ({ state, setQuantity }) => (
  <div>
    <Table
      keys={keys}
      entities={state.allocations}
      state={state}
      row={AllocationsRow}
      setQuantity={setQuantity}
      details={AllocationDetails}
    />
  </div>
);

AllocationsClientList.propTypes = {
  state: PropTypes.object.isRequired,
  setQuantity: PropTypes.func.isRequired,
};

export default AllocationsClientList;
