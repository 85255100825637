import dayjs from 'dayjs';

export const offersInitialState = {
  offersFilters: {
    page: 1,
    text: '',
    color: '',
    year: '',
    aoc: '',
    minPrice: 0,
    maxPrice: 10000,
    minQuantity: 0,
    maxQuantity: 10000,
    format: '',
    ranking: '',
    note: '',
    minNote: 0,
    maxNote: 100,
    packaging: '',
    startTime: Math.floor(dayjs().subtract(1, 'month') / 1000),
    endTime: Math.floor(dayjs() / 1000),
    margin: 0,
    marginApply: 0,
  },
  offers: [],
  totalOffers: 0,
  currentSelectedOffer: {},
  displayDeleteOffer: false,
};

export const offersReducers = (state, { type, payload = {} }) => {
  switch (type) {
    case 'setOffersFilters':
      return {
        ...state,
        offersFilters: { ...state.offersFilters, [payload.key]: payload.value },
      };
    case 'setTotalOffers':
      return {
        ...state,
        totalOffers: payload.value,
      };
    case 'setTime':
      return {
        ...state,
        offersFilters: {
          ...state.offersFilters,
          endTime: payload.end,
          startTime: payload.start,
        },
      };
    default:
      return state;
  }
};
