import React from 'react';
import propTypes from 'prop-types';
// import { toDate } from '../../../../../../admin/config';
import { wineTypes } from '../../../../../../wines/config';

const VintageTable = ({ vintage }) => {
  const color = wineTypes[vintage.wine.color];

  return (
    <div>
      <table>
        <tbody>
          <tr className="tr-alloc-first">
            <td className="td-grey">
              <span className="span-round">...</span>
              Sortie: {/* toDate(vintage.date) */}
              <div className="div-selected" />
              <div className="div-close-alloc" />
              Clôture:
            </td>
          </tr>
          <tr className="tr-alloc">
            <td>
              <div className="td-closing-alloc" />
            </td>
            <td className="td-div-alloc-name">
              {' '}
              <div className="i-alloc">
                <i className={`icon-${color}`} />
              </div>
              {vintage.wine.name}
            </td>
            <td className="td-div-alloc-close">
              <div className="div-close-alloc" />
            </td>
            <td className="td-div-alloc-form">
              format
              <br />
              {vintage.format}
            </td>
            <td className="td-div-alloc-close">
              <div className="div-close-alloc" />
            </td>
            <td className="td-div-alloc">
              Prix
              <br />
              {vintage.price}
            </td>
            <td className="td-div-alloc">
              PS
              <br />
              {vintage.latestSlice.releasePrice}
            </td>
            <td className="td-div-alloc">
              PRC
              <br />
              {vintage.latestSlice.recommendedPrice}
            </td>
            <td className="td-div-alloc-close">
              <div className="div-close-alloc" />
            </td>
            <td className="td-div-alloc">
              <div className="td-closing-alloc" />
            </td>
          </tr>
          <tr className="tr-alloc">
            <td>
              <div className="td-closing-alloc" />
            </td>
            <td className="td-div-alloc-aoc">
              AOC <br /> {vintage.appellation && vintage.appellation.name}
            </td>
            <td className="td-div-alloc-class">
              Classement <br /> {vintage.ranking && vintage.ranking.name}
            </td>
            <td className="td-div-alloc-close">
              <div className="div-close-alloc" />
            </td>
            <td className="td-div-alloc-cond">
              Conditionnement <br /> {vintage.packaging}
            </td>
            <td className="td-div-alloc-close">
              <div className="div-close-alloc" />
            </td>
            <td className="td-div-alloc">
              Prix n-1 <br /> {vintage.price}
            </td>
            <td className="td-div-alloc">
              PS n-1 <br />{' '}
              {vintage.previousList &&
                vintage.previousList.latestSlice.releasePrice}
            </td>
            <td className="td-div-alloc">
              PRC n-1 <br />{' '}
              {vintage.previousList &&
                vintage.previousList.latestSlice.recommendedPrice}
            </td>
            <td className="td-div-alloc-close">
              <div className="div-close-alloc" />
            </td>
            <td>
              <div className="td-closing-alloc" />
            </td>
          </tr>
          <tr className="width-98">
            <td className="width-100">
              <div className={`div-lign-alloc-${vintage.wine.color}`} />
              <div className="margin" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

VintageTable.propTypes = {
  vintage: propTypes.object.isRequired,
};

export default VintageTable;
