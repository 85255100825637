import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

import { usersInitialState } from './app/users/store';
import { offersInitialState, offersReducers } from './app/offers/store';
import { winesInitialState, winesReducer } from './app/wines/store';
import {
  allocationsInitialState,
  allocationsReducer,
} from './app/allocations/store';
import {
  operationInitialState,
  operationReducer,
} from './app/admin/operation/store';
import { invoiceInitialState, invoiceReducer } from './app/admin/invoice/store';

const combineReducers = reducers => (state = {}, action) => {
  const handlingState = reducers.map(reducer => reducer(state, action));

  const newState = handlingState.reduce(
    (prevState, currState) =>
      currState === state ? prevState : { ...prevState, ...currState },
    {},
  );

  return newState;
};

const initialState = {
  ...winesInitialState,
  ...usersInitialState,
  ...offersInitialState,
  ...allocationsInitialState,
  ...operationInitialState,
  ...invoiceInitialState,
};

const defaultReducer = (state, { type, payload = {} }) => {
  switch (type) {
    case 'setState':
      return { ...state, [payload.key]: payload.value };
    case 'resetState':
      return initialState;
    default:
      return state;
  }
};

const reducers = [
  offersReducers,
  winesReducer,
  defaultReducer,
  operationReducer,
  allocationsReducer,
  invoiceReducer,
];

export const StoreContext = createContext(null);

export const StoreProvider = ({ children }) => (
  <StoreContext.Provider
    value={useReducer(combineReducers(reducers), initialState)}
  >
    {children}
  </StoreContext.Provider>
);

StoreProvider.propTypes = {
  children: PropTypes.object.isRequired,
};
