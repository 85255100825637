import gql from 'graphql-tag';

export const LIST_VINTAGES = gql`
  query vintagesFromChateau($chateauUid: ID!) {
    vintagesFromChateau(chateauUid: $chateauUid) {
      uid
      year
      wine {
        name
        color
      }
      referencePrice
      appellation {
        name
      }
      ranking {
        name
      }
      latestSlice {
        releasePrice
        recommendedPrice
      }
    }
  }
`;

export const COUNT_ALLOCATIONS = gql`
  query paginatedAllocationsFromChateau($filter: JSON, $vintageUid: ID!) {
    paginatedAllocationsFromChateau(filter: $filter, vintageUid: $vintageUid)
  }
`;

export const LIST_ALLOCATIONS = gql`
  query filteredAllocationsFromChateau(
    $filter: JSON
    $vintageUid: ID!
    $page: Int = 1
  ) {
    filteredAllocationsFromChateau(
      filter: $filter
      vintageUid: $vintageUid
      page: $page
    ) {
      uid
      trader {
        name
      }
      slice {
        releasePrice
        recommendedPrice
        vintage {
          uid
          year
          wine {
            name
            color
          }
          referencePrice
          appellation {
            name
          }
          ranking {
            name
          }
          latestSlice {
            releasePrice
            recommendedPrice
          }
        }
      }
    }
  }
`;
