import gql from 'graphql-tag';

export const GET_DATA_GRAPH = gql`
  query evolution(
    $vintageUid: String = ""
    $startTimestamp: Int = 1548344185
    $endTimestamp: Int = 1571921385
  ) {
    evolution(
      vintageUid: $vintageUid
      startTimestamp: $startTimestamp
      endTimestamp: $endTimestamp
    )
  }
`;

export const ADD_OFFERS = gql`
  mutation saveToCart($offerUid: String!, $partnerUid: String!) {
    saveToCart(offerUid: $offerUid, partnerUid: $partnerUid) {
      uid
      offers {
        uid
      }
    }
  }
`;
