import React, { useContext } from 'react';

import { useQuery } from 'react-apollo';
import { StoreContext } from '../../../stores';

import Loader from '../loader';

import { GET_CREDENTIALS } from '../../users/store/users/requests';
import history from '../../../history';

export default WrappedComponent => ({ ...props }) => {
  const [state, dispatch] = useContext(StoreContext);

  const { loading, error, data = {} } = useQuery(GET_CREDENTIALS);

  const { me } = data;

  if (error) {
    history.push('/login');
    return '';
  }

  if (state.user !== me) {
    dispatch({
      type: 'setState',
      payload: {
        key: 'user',
        value: me,
      },
    });
  }

  if (loading || !state.user) {
    return (
      <Loader
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      />
    );
  }

  return <WrappedComponent {...props} />;
};
