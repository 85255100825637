import React, { useState } from 'react';
import PropTypes from 'prop-types';
import table from './table.css';

/**
 * Reusable component for a table
 * @param {component} row
 * @param {array} headers
 * @param {object} details if need to display details of an element of the table
 * @param {array} entities data from API to display on Tables
 */

const Table = ({
  headers,
  entities,
  row: Row,
  details: Details,
  Header,
  setQuantity,
}) => {
  const [displayDetails, setDisplayDetails] = useState(false);
  const [displayedItem, setDisplayedItem] = useState({});

  const handleDisplayDetails = item => () => {
    setDisplayedItem(item);
    setDisplayDetails(!displayDetails);
  };

  return (
    <>
      <table>
        <colgroup span={headers.length} className="columns" />
        <tbody>
          {headers.length !== 0 && <Header headers={headers} />}
          {entities.map((item, index) => (
            <Row
              /* eslint-disable */
              setQuantity={setQuantity}
              key={`row-${item.uid}-${index}`}
              item={item}
              handleDisplayDetails={handleDisplayDetails(item)}
              /* eslint-enable */
            />
          ))}
        </tbody>
      </table>
      {displayDetails && (
        <Details
          item={displayedItem}
          handleDisplayDetails={handleDisplayDetails()}
        />
      )}
    </>
  );
};

Table.propTypes = {
  row: PropTypes.func.isRequired,
  details: PropTypes.object,
  entities: PropTypes.array,
  Header: PropTypes.func,
  headers: PropTypes.array,
  setQuantity: PropTypes.func,
};

Table.defaultProps = {
  Header: () => {},
  entities: [],
  headers: [],
  details: {},
  setQuantity: () => {},
};

export default Table;
