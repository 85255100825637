export const option = [
  {
    value: 1546304859,
    label: '2019',
  },
  {
    value: 1514768859,
    label: '2018',
  },
  {
    value: 1483232859,
    label: '2017',
  },
  {
    value: 1451610459,
    label: '2016',
  },
  {
    value: 1420074459,
    label: '2015',
  },
  {
    value: 1388538459,
    label: '2014',
  },
  {
    value: 1357002459,
    label: '2013',
  },
  {
    value: 1325380059,
    label: '2012',
  },
  {
    value: 1293844059,
    label: '2011',
  },
  {
    value: 1262308059,
    label: '2010',
  },
];

export const optionSlice = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
];
