import React from 'react';
import PropTypes from 'prop-types';
import './allocations-filters.css';
import { option, optionSlice } from '../config';

const AllocationsClientFilters = ({
  state,
  dispatch,
  listAllocations,
  countAllocations,
}) => {
  const loadAllocationsAction = () => {
    listAllocations({
      variables: {
        filter: state.allocationsFilters,
        page: state.allocationsFilters.page,
      },
    });
    countAllocations({
      variables: {
        filter: state.allocationsFilters,
      },
    });
  };

  const handleOnChange = key => e => {
    if (key === 'year' || key === 'sliceNumber') {
      dispatch({
        type: 'setAllocationsFilters',
        payload: {
          key,
          value: parseInt(e.target.value, 10),
        },
      });
    } else {
      dispatch({
        type: 'setAllocationsFilters',
        payload: {
          key,
          value: e.target.value,
        },
      });
    }
  };

  return (
    <div className="div-alloc-filter">
      Année
      <select
        value={state.allocationsFilters.year || ''}
        className="select-alloc-filter"
        onChange={handleOnChange('year')}
      >
        {option.map(optn => (
          <option key={optn.value} value={optn.value}>
            {optn.label}
          </option>
        ))}
      </select>
      Vin, galaxie
      <input
        value={state.allocationsFilters.wineName}
        onChange={handleOnChange('wineName')}
      />
      Tranche
      <select
        value={state.allocationsFilters.slice}
        onChange={handleOnChange('sliceNumber')}
      >
        {optionSlice.map(slice => (
          <option key={slice.value} value={slice.value}>
            {slice.label}
          </option>
        ))}
      </select>
      <button type="button" onClick={loadAllocationsAction}>
        filtrer
      </button>
    </div>
  );
};

AllocationsClientFilters.propTypes = {
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  listAllocations: PropTypes.func.isRequired,
  countAllocations: PropTypes.func.isRequired,
};

export default AllocationsClientFilters;
