import React from 'react';
import PropTypes from 'prop-types';
import Table from '../../../../theme/table';
import InvoiceRow from '../invoice-row';
import InvoiceHeader from '../invoice-header';

const headers = [
  'Référence',
  'Bordereau associé',
  'Montant',
  'Status',
  'Télécharger',
];

const InvoiceList = ({ state }) => (
  <Table
    row={InvoiceRow}
    entities={state.invoice}
    headers={headers}
    Header={InvoiceHeader}
  />
);

InvoiceList.propTypes = {
  state: PropTypes.object.isRequired,
};

export default InvoiceList;
