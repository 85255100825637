import React from 'react';
import './wineDetails.css';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import { useQuery } from 'react-apollo';
import { GET_DATA_GRAPH } from '../../../../offers/store/offers-details/requests';
import { Input } from '../../../../theme/input';
import { BASE_URL } from '../../../../../utils/api';
import { wineTypes } from '../../../config';

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

let dataGraph = {
  datasets: [
    {
      data: [10, 29, 45, 84, 350, 84],
    },
  ],
  labels: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep'],
};

const WineDetails = ({ item, cookies, handleDisplayDetails }) => {
  // transform color from db into color for icomoon css
  const color = wineTypes[item.color];

  // set the value get from the evolution query to fill the graph or set to default value
  const onCompleted = data => {
    dataGraph = {
      datasets: [
        {
          data: [
            data.evolution
              ? data.evolution.referencePrice.map(price => price.price)
              : 10,
            29,
            45,
            84,
            350,
            84,
          ],
        },
      ],
      labels: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep'],
    };
  };
  // query for the graph, from currente date - 9 month to current date
  useQuery(GET_DATA_GRAPH, {
    onCompleted,
    variables: {
      startTimestamp: Math.floor(Date.now() / 1000 - 23652000, 10),
      endTimestamp: Math.floor(Date.now() / 1000),
    },
  });

  return (
    <div className="popup">
      <div className="popup-inner">
        <div className="div-fill-grey">
          <span className={`icon-${color}`} />
        </div>
        <div className="columns-1">
          DÉTAILS
          <div className="grey-lign" />
          <table className="table-t" cellSpacing="1">
            <colgroup span="3" className="columns" />
            <tbody>
              <tr className="tr-pop">
                <td className="td-pop">
                  Milésime <br />{' '}
                  <span className="span-strong">{item.year}</span>
                </td>
                <td className="td-pop">
                  Château <br />
                  <span className="span-strong">{item.partner}</span>
                </td>
                <td className="td-pop">
                  AOC
                  <br />
                  <span className="span-strong">{item.aoc}</span>
                </td>
              </tr>
            </tbody>
            <tbody className="tbody-table">
              <tr className="tr-pop">
                <td className="td-pop">
                  Classement <br />{' '}
                  <span className="span-strong">
                    {item.ranking ? item.ranking : null}
                  </span>
                </td>
                <td className="td-pop">
                  Format <br />{' '}
                  <span className="span-strong">{item.format}</span>
                </td>
                <td className="td-pop">
                  Conditionnement <br />{' '}
                  <span className="span-strong">{item.packaging}</span>
                </td>
              </tr>
            </tbody>
            <tbody className="tbody-table">
              <tr className="tr-pop">
                <td className="td-div-details">
                  Prix <br />{' '}
                  <span className="span-strong">{item.price} €</span>
                </td>
                <td className="td-div-details">
                  CV <br /> <span className="span-strong">{item.cv}</span>
                </td>
                <td className="td-div-details">
                  PS <br />{' '}
                  <span className="span-strong">{`${item.PS} €`}</span>
                </td>
                <td className="td-div-details">
                  PRC <br />{' '}
                  <span className="span-strong">{`${item.PRC} €`}</span>
                </td>
              </tr>
            </tbody>
            <tbody className="tbody-table">
              <tr className="tr-pop">
                <td className="td-pop">
                  Régie <br /> <span className="span-strong">{item.regie}</span>
                </td>
                <td className="td-pop">
                  Qté <br />{' '}
                  <span className="span-strong">{item.quantity}</span>
                </td>
                <td className="td-pop">
                  Date <br /> <span className="span-strong">{item.year}</span>
                </td>
              </tr>
            </tbody>
          </table>
          <span>PHOTOS</span>
          <div className="grey-lign-larg" />
          <div className="div-photo">
            <img
              className="span-strong"
              width="100"
              height="100"
              src={`${BASE_URL}/uploads/offers/${item.uid}/0?token=${cookies.cookies.token}`}
              alt="Red dot"
            />
            <img
              className="span-strong"
              width="100"
              height="100"
              src={`${BASE_URL}/uploads/offers/${item.uid}/0?token=${cookies.cookies.token}`}
              alt="Red dot"
            />
          </div>
          <span>DOCUMENTS</span>
          <div className="grey-lign-larg" />
        </div>
        <div className="columns-2">
          <span className="notes">NOTES</span>
          <div className="grey-lign-note" />
          <br />
          <div className="police-notes">
            {item.reviewer} <br />
            {item.noteMin} - {item.noteMax}
          </div>
          <button type="button" onClick={handleDisplayDetails}>
            Fermer
          </button>
        </div>
        <div className="columns-details">
          <span>COMMENTAIRE</span>
          <div className="grey-lign" />
          <div className="police">{item.comment}</div>
          <span className="span-margin">CONDITION SPÉCIALES</span>
          <div className="grey-lign" />
          <div className="police">{item.specialCase}</div>
          <span className="span-margin">EVOLUTIONS</span>
          <div className="grey-lign" />
          <div className="div-input">
            <span className="span-strong">DE</span> <Input />{' '}
            <span className="span-input">À</span> <Input />
          </div>
          <Line data={dataGraph} height={100} options={options} />
        </div>
      </div>
    </div>
  );
};

WineDetails.propTypes = {
  item: PropTypes.object.isRequired,
  cookies: PropTypes.object.isRequired,
  handleDisplayDetails: PropTypes.func.isRequired,
};

export default WineDetails;
