import React from 'react';
import PropTypes from 'prop-types';
import { withWidth } from '@material-ui/core';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import theme from './theme';

import './index.css';

const AppTheme = ({ children, width }) => (
  <MuiThemeProvider theme={createMuiTheme(theme(width))}>
    {children}
  </MuiThemeProvider>
);

AppTheme.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.string.isRequired,
};

export default withWidth()(AppTheme);
