import React from 'react';
import './select.css';
import PropTypes from 'prop-types';

export const Select = ({ onChange, optn }) => (
  <div className="div-select">
    <select onChange={onChange} className="custom-select">
      <option key="" value="">
        Couleur
      </option>
      {optn.map(option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  </div>
);

Select.propTypes = {
  onChange: PropTypes.func.isRequired,
  optn: PropTypes.array.isRequired,
};
