import React from 'react';
import PropTypes from 'prop-types';
import './wines-row.css';
import { wineTypes } from '../../../config';

const OffersRow = ({ item, handleDisplayDetails }) => {
  // transform color from db into color for icomoon css
  const color = wineTypes[item.color] || wineTypes.RO;

  return (
    <>
      <tr>
        <td>
          <div className="div-lign-wine-grey" />
        </td>
      </tr>
      <tr className="tr-row" key={`wine-row-${item.uid}`}>
        <td>
          <div className="td-closing" />
        </td>
        <td className="td-div-ic">
          <i className={`icon-${color}`} />
        </td>
        <td className="td-div-wine-name">{item.name}</td>
        <td className="td-div-wine">
          Millésime
          <br />
          {item.year}
        </td>
        <td className="td-div-wine">
          AOC
          <br />
          {item.aoc}
        </td>
        <td>
          <div className="border-div" />
        </td>
        <td className="td-div">
          CV
          <br />
          {item.CV}
        </td>
        <td className="td-div">
          PS
          <br />
          {item.PS} €
        </td>
        <td className="td-div">
          PRC
          <br />
          {item.PRC} €
        </td>
        <td>
          <div className="border-div" />
        </td>
        <td className="td-div-button">
          <button
            className="button-select"
            type="button"
            onClick={handleDisplayDetails}
          >
            Voir les détails du vins
          </button>
        </td>
        <td />
        <td>
          <div className="td-closing-right" />
        </td>
      </tr>
      <tr className="width-100">
        <td className="width-1000">
          <div className={`div-lign-wine-${item.color}`} />
          <div className="margin" />
        </td>
      </tr>
    </>
  );
};

OffersRow.propTypes = {
  item: PropTypes.object.isRequired,
  handleDisplayDetails: PropTypes.func.isRequired,
};

export default OffersRow;
