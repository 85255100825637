import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from '../../../theme/input';
import Grid from '../../../theme/grid';
import ButtonPage from '../../../theme/button-page';
import './wine-pagination.css';
import PaginationButton from '../../../theme/pagination';

const WinesPagination = ({ state, dispatch, fetchMoreWines }) => {
  const pageRef = React.createRef();
  const [maxPage, setMaxPage] = useState(10);

  /**
   * fetch more data from current page
   *
   * @param {Int} value
   */
  const loadOffersAction = async value => {
    const {
      data: { filteredVintages },
    } = await fetchMoreWines({
      variables: {
        page: value,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return fetchMoreResult;
      },
    });

    dispatch({
      type: 'setState',
      payload: {
        key: 'wines',
        value: filteredVintages.vintages,
      },
    });
  };

  /**
   * Handle the click of page button
   *
   * @param {Object} e
   */
  const handleClick = e => {
    const value = parseInt(e.target.value, 10);
    dispatch({
      type: 'setWinesFilters',
      payload: {
        key: 'page',
        value,
      },
    });
    loadOffersAction(value);
  };

  // call the current page with the value in the input
  const handleClickText = async () => {
    if (!pageRef.current.value) {
      pageRef.current.value = 1;
    }
    loadOffersAction(parseInt(pageRef.current.value, 10));
  };

  // component for paginations, changing depending on currentPage
  return (
    <Grid container>
      <span className="page-wine">Page </span>{' '}
      <Input
        id="page"
        type="number"
        label="page"
        ref={pageRef}
        value={pageRef.current}
      />{' '}
      <ButtonPage onClick={handleClickText} />
      <div className="page-row">
        <PaginationButton
          numberOfItem={state.numberOfVintage}
          currPage={state.wineFilters.page}
          maxPage={maxPage}
          setMaxPage={setMaxPage}
          handleClick={handleClick}
        />
      </div>
    </Grid>
  );
};

WinesPagination.propTypes = {
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  fetchMoreWines: PropTypes.func.isRequired,
};

export default WinesPagination;
