import React from 'react';

const NotFound = () => (
  <div>
    <div>Not found</div>
    <a href="/">Return dashboard</a>
  </div>
);

export default NotFound;
