import React from 'react';
import PropTypes from 'prop-types';
import Table from '../../../theme/table';
import OffersRow from '../offers-row';
import OffersDetails from '../offers-details';

const OffersList = ({ state }) => {
  // map offers and set PS and PRC
  const entities = state.offers
    ? state.offers.map(offer => ({
        ...offer,
        PS: offer.vintage.slices[0].number,
        PRC: offer.vintage.slices[0].releasePrice,
      }))
    : [];

  return <Table entities={entities} row={OffersRow} details={OffersDetails} />;
};

OffersList.propTypes = {
  state: PropTypes.object.isRequired,
};
export default OffersList;
