export default theme => ({
  wrapper: {
    backgroundColor: theme.palette.others.lighGrey,
    height: '9rem',
    width: '9rem',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  name: {
    fontFamily: 'CormorantGaramond-Bold',
    fontSize: '1.3rem',
    fontVariant: 'small-caps',
  },
  subName: {
    fontFamily: 'Montserrat-Regular',
    fontSize: '0.7rem',
    textTransform: 'none',
  },
  link: {
    height: '1.5rem',
    width: '1.5rem',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    marginTop: '0.5rem',
  },
  icon: {
    color: '#FFF',
  },
});
