/**
 * set a timeStamp to a date with YEAR/MONTH/DAY format
 * @param {int} timeStamp
 */

export const toDate = timeStamp => {
  const dateObj = new Date(timeStamp * 1000);
  const month = dateObj.getUTCMonth() + 1; // months from 1-12
  const day = dateObj.getUTCDate();
  const year = dateObj.getUTCFullYear();
  return `${year}/${month}/${day}`;
};
