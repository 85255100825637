import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@material-ui/core';
import { Phone, Print } from '@material-ui/icons';

const FooterMenu = ({ classes }) => (
  <div className={classes.wrapper}>
    <div className={classes.headerWrapper}>
      <p className={classes.header}>Bureau Laurent Quancard</p>
    </div>
    <Typography className={`${classes.text} ${classes.address}`}>
      64 avenue Carnot
    </Typography>
    <Typography className={classes.text}>33200 Bordeaux</Typography>
    <Typography className={classes.text}>
      <Phone className={classes.icon} /> 05 57 87 47 50
    </Typography>
    <Typography className={classes.text}>
      <Print className={classes.icon} /> 05 57 87 47 57
    </Typography>
  </div>
);

FooterMenu.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default FooterMenu;
