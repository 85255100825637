import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@material-ui/core';

import Shortcut from '../shortcut';

const AdminstrativeShortcuts = ({ classes }) => (
  <Grid container>
    <Grid item xs={5} className={classes.billsWrapper}>
      <Shortcut
        name="Administratif"
        subname="Bordereaux et factures"
        link="/administrative"
      />
    </Grid>
  </Grid>
);

AdminstrativeShortcuts.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default AdminstrativeShortcuts;
