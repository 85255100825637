import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@material-ui/core';

import Menu from '../menu';

const MainLayout = ({ children, classes, cookies }) => {
  const token = cookies.get('token');

  return token ? (
    <Grid container className={classes.wrapper}>
      <Grid item xs={2}>
        <Menu />
      </Grid>
      <Grid item xs={10} className={classes.contentWrapper}>
        {children}
      </Grid>
    </Grid>
  ) : (
    ''
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  cookies: PropTypes.object.isRequired,
};

export default MainLayout;
