import React from 'react';
import PropTypes from 'prop-types';
import PaginatedButton from '../../paginated-button';

// create a [maxPage, setMaxPage] = useState(0) before calling the component

const PaginationButton = ({
  numberOfItem,
  currPage,
  handleClick,
  setMaxPage,
  maxPage,
}) => {
  const pageButton = [];
  if (!numberOfItem) {
    setMaxPage(5);
  } else setMaxPage(numberOfItem / 10);
  if (maxPage <= 5) {
    for (let i = 0; i < maxPage; i += 1) {
      pageButton[i] = (
        <PaginatedButton
          key={i.toString()}
          value={i + 1}
          variant="outlined"
          keye={i.toString()}
          type="button"
          onClick={handleClick}
        >
          {i + 1}
        </PaginatedButton>
      );
    }
  } else if (currPage === 1 || currPage === 2 || currPage > maxPage - 1) {
    for (let i = 0; i < 3; i += 1) {
      pageButton[i] = (
        <PaginatedButton
          key={i.toString()}
          value={i + 1}
          variant="outlined"
          keye={i.toString()}
          type="button"
          onClick={handleClick}
        >
          {i + 1}
        </PaginatedButton>
      );
    }
    pageButton[3] = <span key="3 1">...</span>;
    pageButton[4] = (
      <PaginatedButton
        key="4 1"
        variant="outlined"
        keye="4 1"
        type="button"
        value={maxPage + 1}
        onClick={handleClick}
      >
        {parseInt(maxPage, 10) + 1}
      </PaginatedButton>
    );
    // print [1]...[currPage - 1][currPage][currPage + 1]...[maxPage]
  } else {
    pageButton[0] = (
      <PaginatedButton
        key="° 2"
        variant="outlined"
        keye="0 2"
        type="button"
        value={1}
        onClick={handleClick}
      >
        1
      </PaginatedButton>
    );
    pageButton[1] = <span key="1 2">...</span>;
    pageButton[2] = (
      <PaginatedButton
        key="2 2"
        variant="outlined"
        keye="2 2"
        type="button"
        value={currPage - 1}
        onClick={handleClick}
      >
        {currPage - 1}
      </PaginatedButton>
    );
    pageButton[3] = (
      <PaginatedButton
        key="3 2"
        variant="outlined"
        keye="3 2"
        type="button"
        value={currPage}
        onClick={handleClick}
      >
        {currPage}
      </PaginatedButton>
    );
    pageButton[4] = (
      <PaginatedButton
        variant="outlined"
        keye="4 2"
        key="4 2"
        type="button"
        value={currPage + 1}
        onClick={handleClick}
      >
        {currPage + 1}
      </PaginatedButton>
    );
    pageButton[5] = <span key="5 2">...</span>;
    pageButton[6] = (
      <PaginatedButton
        variant="outlined"
        keye="6 2"
        key="6 2"
        type="button"
        value={maxPage + 1}
        onClick={handleClick}
      >
        {parseInt(maxPage, 10) + 1}
      </PaginatedButton>
    );
  }
  return pageButton;
};

PaginatedButton.propTypes = {
  handleClick: PropTypes.func,
  setMaxPage: PropTypes.func,
  currPage: PropTypes.number,
  maxPage: PropTypes.number,
  numberOfItem: PropTypes.number,
};

PaginatedButton.defaultProps = {
  handleClick: () => {},
  setMaxPage: () => {},
  currPage: 0,
  maxPage: 0,
  numberOfItem: 0,
};

export default PaginationButton;
