import gql from 'graphql-tag';

/**
 * Graphql request to get the credentials
 */
export const GET_CREDENTIALS = gql`
  {
    me
  }
`;
