import React, { useContext, useEffect } from 'react';
import { useLazyQuery } from 'react-apollo';
import { StoreContext } from '../../../../stores';
import { LIST_OPERATIONS } from '../store/requests';
import OperationList from '../operation-list';
import OperationPagination from '../operation-pagination';
import './operation.css';

const Operation = () => {
  const [state, dispatch] = useContext(StoreContext);

  const [
    listOperations,
    { loading, fetchMore: fetchMoreOperation },
  ] = useLazyQuery(LIST_OPERATIONS, {
    variables: state.operationFilter,
    onCompleted: ({ paginatedOperations }) => {
      dispatch({
        type: 'setState',
        payload: {
          key: 'operation',
          value: paginatedOperations.operations,
        },
      });
      dispatch({
        type: 'setState',
        payload: {
          key: 'numberOfOperation',
          value: paginatedOperations.total,
        },
      });
    },
  });

  useEffect(() => {
    listOperations({ variables: state.operationFilter });
  }, []);

  return (
    <div className="div-operation">
      <span className="bord">BORDEREAUX</span>
      {loading || !state.operation ? (
        <p>Loading...</p>
      ) : (
        <>
          <div className="margin-right" />
          <OperationPagination
            state={state}
            dispatch={dispatch}
            fetchMoreOperation={fetchMoreOperation}
          />
          <OperationList state={state} />
          <div className="div-close" />
        </>
      )}
    </div>
  );
};

export default Operation;
