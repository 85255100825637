/**
 * Check diff between current date and timeStamp of an object
 * @param {Date} timeStamp
 */

export const timeStampToString = timeStamp => {
  const seconds = Math.round(new Date() / 1000);
  if (seconds - timeStamp < 604800) {
    return "Moins d'une semaine";
  }

  if (seconds - timeStamp > 604800 && seconds - timeStamp < 2628000) {
    return "Moins d'un mois";
  }

  return 'Moins de trois mois';
};
