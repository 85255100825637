import React from 'react';

import { Grid } from '@material-ui/core';

import Profile from '../profile';
import DashboardHeader from '../dashboard-header';
import AdministrativeShortcuts from '../administrative-shortcuts';
import OffersSelected from '../../offers/offers-selected';

const Dashboard = () => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <DashboardHeader />
    </Grid>
    <Grid item xs={2}>
      <AdministrativeShortcuts />
    </Grid>
    <Grid item xs={10}>
      <Profile />
    </Grid>
    <Grid item xs={5}>
      <OffersSelected />
    </Grid>
  </Grid>
);

export default Dashboard;
