import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import { withClientState } from 'apollo-link-state';
import { ApolloClient } from 'apollo-client';
import { ApolloLink, Observable } from 'apollo-link';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { createUploadLink } from 'apollo-upload-client';
import { Cookies, CookiesProvider } from 'react-cookie';
import { BASE_URL } from './utils/api';
import AppTheme from './app/app-theme';

import { StoreProvider } from './stores';
import registerServiceWorker from './registerServiceWorker';

import Routes from './app/Routes';

const cache = new InMemoryCache({ wines: [{}] });

const cookie = new Cookies();

const stateLink = withClientState({
  cache,
});

const request = async operation => {
  const token = cookie.get('token');

  operation.setContext({
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle;
      Promise.resolve(operation)
        .then(oper => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    }),
);

const client = new ApolloClient({
  uri: BASE_URL,
  cache,
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (process.env.NODE_ENV === 'development') {
        if (graphQLErrors)
          graphQLErrors.map(({ message, locations, path }) =>
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
          );
        if (networkError) console.log(`[Network error]: ${networkError}`);
      }
    }),
    stateLink,
    requestLink,
    createUploadLink({
      uri: BASE_URL,
      credentials: 'same-origin',
    }),
  ]),
});

client.onResetStore(stateLink.writeDefaults);

/* eslint-disable no-undef */
ReactDOM.render(
  <StoreProvider>
    <AppTheme>
      <ApolloProvider client={client}>
        <CookiesProvider>
          <Routes />
        </CookiesProvider>
      </ApolloProvider>
    </AppTheme>
  </StoreProvider>,
  document.getElementById('root'),
);
/* eslint-enable no-undef */
registerServiceWorker();
