import React from 'react';
import PropTypes from 'prop-types';
import Table from '../../../theme/table';
import WinesRow from '../wines-row';
import WinesDetails from '../wines-details';

const WinesList = ({ state }) => (
  <Table entities={state.wines} row={WinesRow} details={WinesDetails} />
);

WinesList.propTypes = {
  state: PropTypes.object.isRequired,
};

export default WinesList;
