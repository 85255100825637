import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '../../../../../../theme/grid';
import { Input } from '../../../../../../theme/input';
import ButtonPage from '../../../../../../theme/button-page';
import PaginationButton from '../../../../../../theme/pagination';

const AllocationsChateauDetailsPagination = ({
  fetchMoreAllocationsChateau,
  dispatch,
  state,
}) => {
  const pageRef = useRef('');
  const [maxPage, setMaxPage] = useState(10);

  /**
   * fetch more data from current page
   *
   * @param {Int} value
   */
  const loadOffersAction = async value => {
    const {
      data: { filteredAllocationsFromChateau },
    } = await fetchMoreAllocationsChateau({
      variables: {
        page: value,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return fetchMoreResult;
      },
    });

    dispatch({
      type: 'setState',
      payload: {
        key: 'allocationsChateau',
        value: filteredAllocationsFromChateau,
      },
    });
  };

  /**
   * Handle the click of page button
   *
   * @param {Object} e
   */
  const handleClick = e => {
    const value = parseInt(e.target.value, 10);
    dispatch({
      type: 'setAllocationsChateauFilters',
      payload: {
        key: 'page',
        value,
      },
    });
    loadOffersAction(value);
  };

  // call the page value in the input
  const handleClickText = async () => {
    if (!pageRef.current.value) {
      pageRef.current.value = 1;
    }
    loadOffersAction(parseInt(pageRef.current.value, 10));
  };

  return (
    <Grid container>
      <Grid size="11">
        <div className="page-row">
          <span className="page">Page</span>{' '}
          <Input
            id="page"
            type="number"
            label="page"
            ref={pageRef}
            value={pageRef.current}
          />{' '}
          <ButtonPage onClick={handleClickText} />
          <div className="page-row">
            <PaginationButton
              numberOfItem={state.allocationsChateauFilters.page}
              currPage={state.totalAllocationsChateau}
              maxPage={maxPage}
              setMaxPage={setMaxPage}
              handleClick={handleClick}
            />
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

AllocationsChateauDetailsPagination.propTypes = {
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  fetchMoreAllocationsChateau: PropTypes.func,
};

AllocationsChateauDetailsPagination.defaultProps = {
  fetchMoreAllocationsChateau: () => {},
};

export default AllocationsChateauDetailsPagination;
