import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { KeyboardArrowRight } from '@material-ui/icons';
import { colors } from '../config';
import { InputWine, Input } from '../../../theme/input';
import Select from '../../../theme/select';
import './offers-filters.css';
import FilterButton from '../../../theme/filter-button';

const OffersFilters = ({ state, dispatch, listOffers, getTotalOffers }) => {
  // fetch offers with filters
  const loadOffersAction = () => {
    listOffers({ variables: state.offersFilters });
    getTotalOffers({ variables: state.offersFilters });
  };

  const [disabled, setDisabled] = useState('disabled');

  // set Margin when click and change in the table
  const setMargin = () => {
    dispatch({
      type: 'setOffersFilters',
      payload: {
        key: 'marginApply',
        value: state.offersFilters.margin,
      },
    });
  };

  /**
   * Set the value on the filters state
   * @param {String} key
   */
  const handleOnChange = key => e => {
    let { value } = e.target;
    if (
      key === 'minPrice' ||
      key === 'maxPrice' ||
      key === 'minQuantity' ||
      key === 'maxQuantity'
    ) {
      value = parseInt(value, 10);
      /* eslint-disable no-restricted-globals */
      if (isNaN(value)) {
        value = '';
      }
      /* eslint-disable no-restricted-globals */
    }
    dispatch({
      type: 'setOffersFilters',
      payload: {
        key,
        value,
      },
    });
  };

  // access on note input only when input of name author is filled
  if (state.offersFilters.note && disabled === 'disabled') setDisabled('');

  if (!state.offersFilters.note && disabled === '') setDisabled('disabled');

  /**
   * set the value to have all the date(in timeStamp) between start and end in back-end
   * @param {int(timeStamp)} start
   * @param {int(timeStamp)} end
   */
  const handleClickBox = (start, end) => e => {
    if (e.checked === true) {
      dispatch({
        type: 'setTime',
        payload: {
          start: 0,
          end: Math.floor(Date.now() / 1000),
        },
      });
    } else {
      dispatch({
        type: 'setTime',
        payload: {
          start,
          end,
        },
      });
    }
  };

  return (
    <div className="div-offer-col">
      <div className="div-filter-row">
        <span className="wine">Vin</span>
        <InputWine
          type=""
          id="wine"
          label="Vin"
          onChange={handleOnChange('text')}
          value={state.offersFilters.text}
        />
        <Select onChange={handleOnChange('color')} optn={colors} />
        <Input
          placeholder="Millésime"
          id="date"
          type="number"
          label="milésime"
          value={state.offersFilters.year}
          onChange={handleOnChange('year')}
        />
        <Input
          type=""
          placeholder="AOC"
          value={state.offersFilters.aoc}
          id="AOC"
          label="appellation"
          onChange={handleOnChange('aoc')}
        />
        <Input
          placeholder="Classement"
          id="ranking"
          type=""
          label="ranking"
          value={state.offersFilters.ranking}
          onChange={handleOnChange('ranking')}
        />
        <Input
          placeholder="Format"
          id="format"
          type=""
          label="format"
          value={state.offersFilters.format}
          onChange={handleOnChange('format')}
        />
        <Input
          placeholder="Conditionnement"
          id="packaging"
          type=""
          label="packaging"
          value={state.offersFilters.packaging}
          onChange={handleOnChange('packaging')}
        />
        <Input
          placeholder="Nom de l'auteur"
          id="note"
          type=""
          label="note"
          value={state.offersFilters.note}
          onChange={handleOnChange('note')}
        />
        <Input
          placeholder="Note max"
          type="number"
          value={state.offersFilters.maxNote}
          disabled={disabled}
          onChange={handleOnChange('maxNote')}
        />
        <Input
          placeholder="Note min"
          type="number"
          value={state.offersFilters.minNote}
          disabled={disabled}
          onChange={handleOnChange('minNote')}
        />
      </div>
      <div className="div-filter-row">
        <span className="wine">Prix</span>
        <Input
          placeholder="minPrice"
          value={state.offersFilters.minPrice}
          type="number"
          id="minprice"
          label="minprice"
          onChange={handleOnChange('minPrice')}
        />{' '}
        <span className="wine">à</span>{' '}
        <Input
          value={state.offersFilters.maxPrice}
          type="number"
          id="maxprice"
          label="maxprice"
          onChange={handleOnChange('maxPrice')}
        />
        <span className="wine">Quantité</span>
        <Input
          value={state.offersFilters.minQuantity}
          type="number"
          id="minquantity"
          label="minquantity"
          onChange={handleOnChange('minQuantity')}
        />{' '}
        <span className="wine">à</span>{' '}
        <Input
          value={state.offersFilters.maxQuantity}
          type="number"
          id="maxquantity"
          label="maxquantity"
          onChange={handleOnChange('maxQuantity')}
        />
        <span className="wine">Date :</span>{' '}
        <input
          className="checkbox"
          type="checkbox"
          onClick={handleClickBox(
            Math.floor(Date.now() / 1000) - 7884000,
            Math.floor(Date.now() / 1000),
          )}
        />{' '}
        <span className="wine">Moins de trois mois </span>
        <input
          className="checkbox"
          type="checkbox"
          onClick={handleClickBox(
            Math.floor(Date.now() / 1000) - 2628000,
            Math.floor(Date.now() / 1000),
          )}
        />{' '}
        <span className="wine">Moins d&apos;un mois </span>
        <input
          className="checkbox"
          type="checkbox"
          onClick={handleClickBox(
            Math.floor(Date.now() / 1000) - 604800,
            Math.floor(Date.now() / 1000),
          )}
        />{' '}
        <span className="wine">Moins d&apos;une semaine</span>
      </div>
      <div className="row">
        <div className="div-lign-but" />
        <FilterButton onClick={loadOffersAction}>
          Mettre à jour les filtres
        </FilterButton>
      </div>
      <div className="row-marge">
        <span className="margin-row">Marge</span>{' '}
        <div className="span-margin" />
        <Input
          type="number"
          id="marge"
          value={state.offersFilters.margin}
          onChange={handleOnChange('margin')}
        />
        <span className="span-percent">%</span>
        {/* eslint-disable */}
        <div className="buttonMargin" type="button" onClick={setMargin}>
          <span className="margin-span">Appliquer</span>{' '}
          <div className="round-marg">
            {' '}
            <KeyboardArrowRight className="button-page-icon" />
          </div>
        </div>
        {/* eslint-enable */}
      </div>
    </div>
  );
};

OffersFilters.propTypes = {
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  listOffers: PropTypes.func.isRequired,
  getTotalOffers: PropTypes.func.isRequired,
};

export default OffersFilters;
