import React from 'react';
import PropTypes from 'prop-types';

const AuthenticationLayout = ({ children, classes }) => (
  <div className={classes.wrapper}>{children}</div>
);

AuthenticationLayout.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
};

export default AuthenticationLayout;
