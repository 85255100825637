import React, { useContext, useEffect } from 'react';

import { useLazyQuery } from 'react-apollo';
import Grid from '../../theme/grid';
import WinesList from '../wines-list';
import { StoreContext } from '../../../stores';
import { LIST_VINTAGES } from '../../vintages/store/vintages/requests';
import WinesFilters from '../wines-filters';
import WinesPagination from '../wine-pagination';

const Wines = () => {
  const [state, dispatch] = useContext(StoreContext);

  // fetch wines
  const [listWines, { loading, fetchMore: fetchMoreWines }] = useLazyQuery(
    LIST_VINTAGES,
    {
      onCompleted: ({ filteredVintages }) => {
        dispatch({
          type: 'setState',
          payload: {
            key: 'wines',
            value: filteredVintages.vintages,
          },
        });
        dispatch({
          type: 'setState',
          payload: {
            key: 'numberOfVintage',
            value: filteredVintages.total,
          },
        });
      },
    },
  );

  // fetch wines when component mount
  useEffect(() => {
    listWines({
      variables: {
        page: state.wineFilters.page,
        filter: state.wineFilters,
      },
    });
  }, []);

  return (
    <Grid container>
      <div className="search-div">RECHERCHE</div>
      <WinesFilters listWines={listWines} state={state} dispatch={dispatch} />
      {loading || !state.wines ? (
        <p>Loading</p>
      ) : (
        <>
          <WinesPagination
            state={state}
            dispatch={dispatch}
            fetchMoreWines={fetchMoreWines}
          />
          <WinesList listWines={listWines} state={state} />
        </>
      )}
    </Grid>
  );
};

export default Wines;
