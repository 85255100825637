import React from 'react';
import './operation-details.css';
import PropTypes from 'prop-types';
import { toDate } from '../../../../config';

const OperationDetails = ({ item, handleDisplayDetails }) => (
  <div className="popup-op">
    <div className="popup-inner-op">
      <div className="div-grey-det">
        Bordereau n°{item.number}{' '}
        <button
          className="button-close"
          type="button"
          onClick={handleDisplayDetails}
        >
          fermer
        </button>
        <span className="icon-download" />
      </div>
      <table>
        <tbody>
          <tr className="tr-op-det">
            <th className="th-det">Acheteur</th>
            <th className="th-det">Vendeur</th>
            <th className="th-det">Date</th>
          </tr>
          <tr className="tr-op-det">
            <td className="td-det-buyer">{item.buyerPartner}</td>
            <td className="td-det-sales">{item.salesPartner}</td>
            <td>{toDate(item.date)}</td>
          </tr>
          <tr className="tr-op-det">
            <td className="td-det-buyer">{item.buyerAddress}</td>
            <td className="td-det-sales">{item.salesAddress}</td>
          </tr>
          <tr className="tr-op-det">
            <td className="td-det-buyer">
              {item.buyerCity}
              {item.buyerPostalCode}
            </td>
            <td className="td-det-sales">
              {item.salesCity}
              {item.salesPostalCode}
            </td>
          </tr>
          <tr className="tr-op-det">
            <td className="td-det-buyer">{item.buyerEmail}</td>
            <td className="td-det-sales">{item.salesEmail}</td>
          </tr>
          <tr className="tr-op-det">
            <td className="td-det-buyer">{item.buyerPhone}</td>
            <td className="td-det-sales">{item.salesPhone}</td>
          </tr>
        </tbody>
      </table>
      <table>
        <tbody>
          <tr className="tr-det-head">
            <th className="th-det-wine-name">Vin</th>
            <th className="th-det-wine">Format</th>
            <th className="th-det-wine">Conditionnement</th>
            <th className="th-det-wine">Prix unité</th>
            <th className="th-det-wine">Quantité</th>
            <th className="th-det-wine">Total Produit</th>
          </tr>
          <tr className="tr-op-det">
            <td className="td-det-wine-name">{item.offers.properties.name}</td>
            <td className="td-det-wine">{item.offers.properties.format}</td>
            <td className="td-det-wine">{item.offers.properties.packaging}</td>
            <td className="td-det-wine">{item.offers.properties.price}</td>
            <td className="td-det-wine">{item.quantity}</td>
            <td className="td-det-wine">{item.price * item.quantity}</td>
          </tr>
        </tbody>
      </table>
      <span className="span-total">
        {' '}
        Total <br />
        {/* sum of "total produit" */}
      </span>
    </div>
  </div>
);

OperationDetails.propTypes = {
  item: PropTypes.object.isRequired,
  handleDisplayDetails: PropTypes.func.isRequired,
};

export default OperationDetails;
