import React, { useContext } from 'react';
import { Line } from 'react-chartjs-2';
import { useQuery, useMutation } from 'react-apollo';
import PropTypes from 'prop-types';
import './offersDetail.css';
import {
  GET_DATA_GRAPH,
  ADD_OFFERS,
} from '../../../store/offers-details/requests';
import { Input } from '../../../../theme/input';
import { wineTypes } from '../../../../wines/config';
import { BASE_URL } from '../../../../../utils/api';
import { StoreContext } from '../../../../../stores';
import { timeStampToString } from '../../config';

// option for graph
const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

// value by default for graph
let dataGraph = {
  datasets: [
    {
      data: [10, 29, 45, 84, 350, 84],
    },
  ],
  labels: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep'],
};

const OffersDetails = ({ item, handleDisplayDetails, cookies }) => {
  const [state] = useContext(StoreContext);

  const [addOffersSelected] = useMutation(ADD_OFFERS);
  // transform color from db into color for icomoon css
  const color = wineTypes[item.vintage.wine.color];

  const onCompleted = data => {
    dataGraph = {
      datasets: [
        {
          data: [
            data.evolution
              ? data.evolution.referencePrice.map(price => price.price)
              : 10,
            29,
            45,
            84,
            350,
            84,
          ],
        },
      ],
      labels: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep'],
    };
  };
  useQuery(GET_DATA_GRAPH, {
    onCompleted,
    variables: {
      vintageUid: item.vintage.uid,
      startTimestamp: Math.floor(Date.now() / 1000 - 23652000),
      endTimestamp: Math.floor(Date.now() / 1000),
    },
  });

  const mutateOffers = () => {
    addOffersSelected({
      variables: {
        offerUid: item.uid,
        partnerUid: state.user.partner.properties.uid,
      },
    });

    handleDisplayDetails();
  };

  return (
    <div className="popup">
      <div className="popup-inner">
        <div className="div-fill-grey">
          <span className={`icon-${color}`} />
          {item.vintage.wine.name}
          <button
            type="button"
            className="button-select-offer"
            onClick={mutateOffers}
          >
            Selectionner cette offre
          </button>
        </div>
        <div className="columns-1">
          DÉTAILS
          <div className="grey-lign" />
          <table className="table-t" cellSpacing="1">
            <colgroup span="3" className="columns" />
            <tbody>
              <tr className="tr-pop">
                <td className="td-pop">
                  Milésime <br />{' '}
                  <span className="span-strong">{item.vintage.year}</span>
                </td>
                <td className="td-pop">
                  Château <br />
                  <span className="span-strong">{item.partner.name}</span>
                </td>
                <td className="td-pop">
                  AOC
                  <br />
                  <span className="span-strong">
                    {item.vintage.appellation.name}
                  </span>
                </td>
              </tr>
            </tbody>
            <tbody className="tbody-table">
              <tr className="tr-pop">
                <td className="td-pop">
                  Classement <br />{' '}
                  <span className="span-strong">
                    {item.vintage.ranking ? item.vintage.ranking.name : null}
                  </span>
                </td>
                <td className="td-pop">
                  Format <br />{' '}
                  <span className="span-strong">{item.format}</span>
                </td>
                <td className="td-pop">
                  Conditionnement <br />{' '}
                  <span className="span-strong">{item.packaging}</span>
                </td>
              </tr>
            </tbody>
            <tbody className="tbody-table">
              <tr className="tr-pop">
                <td className="td-div-details">
                  Prix <br />{' '}
                  <span className="span-strong">{item.price} €</span>
                </td>
                <td className="td-div-details">
                  CV <br /> <span className="span-strong">{item.cv}</span>
                </td>
                <td className="td-div-details">
                  PS <br />{' '}
                  <span className="span-strong">{`${item.PS} €`}</span>
                </td>
                <td className="td-div-details">
                  PRC <br />{' '}
                  <span className="span-strong">{`${item.PRC} €`}</span>
                </td>
              </tr>
            </tbody>
            <tbody className="tbody-table">
              <tr className="tr-pop">
                <td className="td-pop">
                  Régie <br /> <span className="span-strong">{item.regie}</span>
                </td>
                <td className="td-pop">
                  Qté <br />{' '}
                  <span className="span-strong">{item.quantity}</span>
                </td>
                <td className="td-pop">
                  Date <br />{' '}
                  <span className="span-strong">
                    {timeStampToString(item.date)}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <span>PHOTOS</span>
          <div className="grey-lign-larg" />
          <div className="div-photo">
            <img
              className="span-strong"
              width="100"
              height="100"
              src={`${BASE_URL}/uploads/offers/${item.uid}/0?token=${cookies.cookies.token}`}
              alt="Red dot"
            />
            <img
              className="span-strong"
              width="100"
              height="100"
              src={`${BASE_URL}/uploads/offers/${item.uid}/1?token=${cookies.cookies.token}`}
              alt="Red dot"
            />
          </div>
          <span>DOCUMENTS</span>
          <div className="grey-lign-larg" />
        </div>
        <div className="columns-2">
          <span className="notes">NOTES</span>
          <div className="grey-lign-note" />
          <br />
          <div className="note-div">
            {item.vintage.notes.map(note => (
              <div key={note.reviewer.name}>
                {note.reviewer.name}
                <br />
                {note.max} - {note.min}
              </div>
            ))}
          </div>
          <button type="button" onClick={handleDisplayDetails}>
            Fermer
          </button>
        </div>
        <div className="columns-details">
          <span>COMMENTAIRE</span>
          <div className="grey-lign" />
          <div className="police">{item.comment}</div>
          <span className="span-margin">CONDITION SPÉCIALES</span>
          <div className="grey-lign" />
          <div className="police">{item.specialCase}</div>
          <span className="span-margin">EVOLUTIONS</span>
          <div className="grey-lign" />
          <div className="div-input-det">
            <span className="span-strong">DE</span> <Input />{' '}
            <span className="span-input">À</span> <Input />
          </div>
          <Line data={dataGraph} height={100} options={options} />
        </div>
      </div>
    </div>
  );
};

OffersDetails.propTypes = {
  item: PropTypes.object.isRequired,
  handleDisplayDetails: PropTypes.func.isRequired,
  cookies: PropTypes.object.isRequired,
};

export default OffersDetails;
