import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-apollo';

import { logoutRequest } from '../../store/authentication/requests';

const Logout = ({ cookies, history }) => {
  const { client } = useQuery(logoutRequest);

  useEffect(() => {
    const logoutActions = async () => {
      cookies.remove('token');
      await client.resetStore();
      history.push('login');
    };

    logoutActions();
  });

  return <div>Déconnexion</div>;
};

Logout.propTypes = {
  cookies: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default Logout;
