import React from 'react';
import PropTypes from 'prop-types';
import history from '../../../../../history';

const AllocationsChateauVintageList = ({ state, dispatch }) => {
  const handleClick = vintage => () => {
    // eslint-disable-next-line no-undef
    dispatch({
      type: 'setState',
      payload: {
        key: 'vintageSelected',
        value: vintage,
      },
    });
    history.push('allocations/detail');
  };

  return (
    <div>
      {state.vintageFromChateau.map((vintage, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={vintage.uid + index}>
          {vintage.wine.name}{' '}
          <button onClick={handleClick(vintage)} type="button">
            Go to vintage
          </button>
        </div>
      ))}
    </div>
  );
};

AllocationsChateauVintageList.propTypes = {
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default AllocationsChateauVintageList;
