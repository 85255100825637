import React from 'react';
import PropTypes from 'prop-types';

import { NavLink } from 'react-router-dom';
import { KeyboardArrowRight } from '@material-ui/icons';
import { Typography } from '@material-ui/core';

const Shortcut = ({ classes, name, subname, link }) => (
  <div className={classes.wrapper}>
    <Typography className={classes.name}>{name}</Typography>
    <Typography className={classes.subName}>{subname}</Typography>
    <NavLink to={link} className={classes.link}>
      <KeyboardArrowRight className={classes.icon} />
    </NavLink>
  </div>
);

Shortcut.propTypes = {
  classes: PropTypes.object.isRequired,
  link: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  subname: PropTypes.string.isRequired,
};

export default Shortcut;
