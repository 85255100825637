import React from 'react';
import propTypes from 'prop-types';

const AllocationsChateauDetailsRow = ({ item }) => (
  <table>
    <tbody>
      <tr>
        <td>{item.trader.name}</td>
      </tr>
      <tr>
        <td />
      </tr>
    </tbody>
  </table>
);

AllocationsChateauDetailsRow.propTypes = {
  item: propTypes.object.isRequired,
};

export default AllocationsChateauDetailsRow;
