import React from 'react';
import PropTypes from 'prop-types';
import Table from '../../../../theme/table';
import OperationRow from '../operation-row';
import OperationHeader from '../operation-header';
import OperationDetails from '../operation-details';

const headers = [
  'Référence',
  'Acheteur',
  'Vendeur',
  'Date',
  'Montant',
  'Détails',
  'Télécharger',
];

const OperationList = ({ state }) => (
  <Table
    row={OperationRow}
    details={OperationDetails}
    entities={state.operation}
    headers={headers}
    Header={OperationHeader}
  />
);

OperationList.propTypes = {
  state: PropTypes.object.isRequired,
};

export default OperationList;
