import gql from 'graphql-tag';

/**
 * Graphql request to login
 *
 * @param email
 * @param password
 */
export const loginRequest = gql`
  query login($email: String!, $password: String!) {
    login(email: $email, password: $password)
  }
`;

/**
 * Graphql request to logout
 */
export const logoutRequest = gql`
  query logout {
    logout
  }
`;
