export const operationInitialState = {
  operationFilter: {
    page: 1,
  },
  operation: undefined,
  numberOfOperation: undefined,
};

export const operationReducer = (state, { type, payload = {} }) => {
  switch (type) {
    case 'setOperationFilter':
      return {
        ...state,
        operationFilter: {
          ...state.operationFilters,
          [payload.key]: payload.value,
        },
      };
    default:
      return state;
  }
};
