import React from 'react';
import './PaginatedButton.css';
import PropTypes from 'prop-types';

export const PaginatedButton = ({
  variant,
  keye,
  value,
  onClick,
  children,
}) => (
  <button
    className="paginated-button"
    type="button"
    variant={variant}
    key={keye}
    value={value}
    onClick={onClick}
  >
    {children}
  </button>
);

PaginatedButton.defaultProps = {
  keye: '',
  variant: '',
  value: '',
  onClick: () => {},
  children: {},
};

PaginatedButton.propTypes = {
  keye: PropTypes.any,
  variant: PropTypes.any,
  value: PropTypes.any,
  onClick: PropTypes.func,
  children: PropTypes.any,
};
