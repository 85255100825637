import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@material-ui/core';

const DashboardHeader = ({ classes }) => (
  <div className={classes.wrapper}>
    <Typography className={classes.text}>MON</Typography>
    <Typography className={classes.text}>DASHBOARD</Typography>
  </div>
);

DashboardHeader.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default DashboardHeader;
