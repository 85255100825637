import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Input } from '../../../../theme/input';
import Grid from '../../../../theme/grid';
import ButtonPage from '../../../../theme/button-page';
import PaginationButton from '../../../../theme/pagination';

const AllocationsClientPagination = ({
  state,
  dispatch,
  fetchMoreAllocations,
}) => {
  const pageRef = useRef('');
  const [maxPage, setMaxPage] = useState(10);

  /**
   * fetch more data from current page
   *
   * @param {Int} value
   */
  const loadOffersAction = async value => {
    const {
      data: { filteredAllocations },
    } = await fetchMoreAllocations({
      variables: {
        page: value,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return fetchMoreResult;
      },
    });

    dispatch({
      type: 'setState',
      payload: {
        key: 'allocations',
        value: filteredAllocations,
      },
    });
  };

  /**
   * Handle the click of page button
   *
   * @param {Object} e
   */
  const handleClick = e => {
    const value = parseInt(e.target.value, 10);
    dispatch({
      type: 'setAllocationsFilters',
      payload: {
        key: 'page',
        value,
      },
    });
    loadOffersAction(value);
  };

  // call the page value in the input
  const handleClickText = async () => {
    if (!pageRef.current.value) {
      pageRef.current.value = 1;
    }
    loadOffersAction(parseInt(pageRef.current.value, 10));
  };

  return (
    <Grid container>
      <Grid size="11">
        <div className="page-row">
          <span className="page">Page</span>{' '}
          <Input
            id="page"
            type="number"
            label="page"
            ref={pageRef}
            value={pageRef.current}
          />{' '}
          <ButtonPage onClick={handleClickText} />
          <div className="page-row">
            <PaginationButton
              currPage={state.allocationsFilters.page}
              numberOfItem={state.totalAllocations}
              maxPage={maxPage}
              setMaxPage={setMaxPage}
              handleClick={handleClick}
            />
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

AllocationsClientPagination.propTypes = {
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  fetchMoreAllocations: PropTypes.func,
};

AllocationsClientPagination.defaultProps = {
  fetchMoreAllocations: () => {},
};

export default AllocationsClientPagination;
